import React, { useEffect, useState } from "react";
import Header from "../../UI/Header/Header";
import classes from "./Review.module.css";
import orderClasses from "./Order.module.css";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  PostReviewHandler,
  getProductByIdHandler,
} from "../../Store/Product/ProductActions";
import MainHeader from "../Home/MainHeader";
import MainFooter from "../../UI/Footer/MainFooter";
import noImg from "../../Assets/Images/noImage.png";
import star1 from "../../Assets/Images/Star1.png";
import star2 from "../../Assets/Images/Star2.png";
import camera from "../../Assets/Images/Camera.png";
import {
  ReturnHandle,
  getOrderByIdHandler,
} from "../../Store/Order/OrderActions";
import { orderActions } from "../../Store/Order/OrderSlice";
import { storeActions } from "../../Store/StoreDetail/StoreSlice";
import { getAdminStoreHandle } from "../../Store/StoreDetail/StoreActions";


const ReturnOrder = () => {
  const verfiyToken = useSelector((state) => state.auth.verfiyToken);
  const product = useSelector((state) => state.product?.product);
  const Customer = useSelector((state) => state.auth.Customer);
  const orderById = useSelector((state) => state.order.orderById);
  const [text, setText] = useState("");
  const [uploadedImages, setUploadedImages] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { orderId } = useParams();
  const {id} = useParams();
  const storeById = useSelector((state) => state.store.storeById);

  const [selectedReason, setSelectedReason] = useState("");
  const [cancelOrderReasonDescription, setCancelOrderReasonDescription] =
    useState("");
  const [msg, setMsg] = useState("");
  const [selectedQuantity, setSelectedQuantity] = useState(1);
  const authToken = localStorage.getItem("authToken");


  const handleChange = (event) => {
    setSelectedReason(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    setCancelOrderReasonDescription(event.target.value);
  };


  useEffect(() => {
    dispatch(getAdminStoreHandle(storeById?.StoreId));
  }, [dispatch, storeById]);


  useEffect(() => {
    if (orderId) {
      dispatch(getProductByIdHandler(storeById?.StoreId, orderId));
    }
  }, [dispatch, storeById?.StoreId, orderId]);



  useEffect(() => {
    if (id) {
      dispatch(storeActions.setStoreId(id));
    }
  }, [id]);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = () => {
      const imageUrl = reader.result;
      setUploadedImages((prevImages) => [...prevImages, imageUrl]);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleImageRemove = (index) => {
    setUploadedImages((prevImages) => {
      const updatedImages = [...prevImages];
      updatedImages.splice(index, 1);
      return updatedImages;
    });
  };

  const matchedProductDetails = orderById?.CustomerDetail[0]?.ProdDetail?.filter(prodDetail => prodDetail?.FKProdID == product?.PKID);

  console.log("orderById--------",orderById)

  console.log("matchedProductDetails--------",matchedProductDetails)

  
  const submitHandler = () => {
    if (
      selectedReason === "Other" &&
      cancelOrderReasonDescription.trim() === ""
    ) {
      setMsg("Please enter a description for the selected reason.");
      return;
    }
    

    dispatch(
      ReturnHandle(authToken, {
        customerID: Customer?._id,
        OrderID: orderById?._id,
        Product_Id: matchedProductDetails[0]?.FKProdID,
        // Qty: matchedProductDetails?.Qty,
        Qty: selectedQuantity,
        ReasonforReturn: selectedReason,
        ReturnOrderReasonDescription: cancelOrderReasonDescription,
        Rate: product?.SaleRate,
        ProdImage: uploadedImages,
        appKeyToken: verfiyToken,
      })
    ).then((result) => {
      if (result.status === "success") {
    
        navigate(`/OrderSummary/${orderById?.OrderID}`)
      }
    });
  };

  const handleHeaderClick = () => {
    if (location.key === 'default') {
      navigate(`/${id}/home`);
    } else {
      navigate(-1);
    }
  };

  const handleCameraUpload = () => {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      const video = document.createElement("video");
      const canvas = document.createElement("canvas");

      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then((stream) => {
          video.srcObject = stream;
          video.play();

          video.onloadedmetadata = () => {
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            const context = canvas.getContext("2d");
            context.drawImage(video, 0, 0, canvas.width, canvas.height);
            const imageUrl = canvas.toDataURL("image/png");
            setUploadedImages((prevImages) => [...prevImages, imageUrl]);
            stream.getTracks().forEach((track) => track.stop());
            document.body.appendChild(video);
            document.body.appendChild(canvas);
            document.body.removeChild(video);
            document.body.removeChild(canvas);
          };
        })
        .catch((error) => {
          console.error("Error accessing the camera:", error);
        });
    } else {
      console.error("getUserMedia is not supported on this browser");
    }
  };

  return (
    <>
      <div className={`container-fluid ${classes.SubmitReviewPage}`}>
        <div className={`${classes.SubmitReviewNavigation}`}>
          <MainHeader />
        </div>

        <div className={`${classes.SubmitReviewWrapper}`}>
          <Header heading={"Return Order"} onClick={handleHeaderClick}/>
          <div className="container-fluid">
            <div className="row">
              <div
                className={`col-xl-6 col-lg-6 col-md-12 col-sm-12 pt-0  ${classes.Product}`}
              >
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex flex-column">
                    <h6 className="m-0">{product?.NameToDisplay}</h6>
                    <p className="m-0">₹{product?.SaleRate}.00</p>
                    <div className="d-flex gap-2 ">
                      {" "}
                      <p className="m-0">Choose Quantity : </p>
                      <div>
                        <select
                          value={selectedQuantity}
                          onChange={(e) =>
                            setSelectedQuantity(parseInt(e.target.value))
                          }
                          className={` pointer ${classes.SubmitQty}`}
                        >
                          {[...Array(matchedProductDetails[0]?.Qty)].map(
                            (_, index) => (
                              <option key={index + 1} value={index + 1}>
                                {index + 1}
                              </option>
                            )
                          )}
                        </select>
                      </div>
                      <div></div>
                    </div>
                  </div>
                  <div className="d-flex align-items-center">
                    {product?.Images?.length > 0 && product?.Images ? (
                      <img
                        variant="top"
                        src={product?.Images[0]}
                        alt="imageNotAvailable"
                        className={`${classes.Image}`}
                      />
                    ) : (
                      <img
                        variant="top"
                        src={noImg}
                        alt="imageNotAvailable"
                        className={`${classes.Image}`}
                      />
                    )}
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="d-flex flex-column gap-3 col-12">
                    <div className={`${orderClasses.cancelText}`}>
                      <h6>Reason for Return</h6>
                    </div>

                    <div className="d-flex align-items-center gap-2">
                      <input
                        type="radio"
                        name="selectedReasons"
                        value="I want to change address for the order"
                        checked={
                          selectedReason ===
                          "I want to change address for the order"
                        }
                        onChange={handleChange}
                      />
                      <h6 className="mb-0">
                        I want to change address for the order
                      </h6>
                    </div>
                    <div className="d-flex align-items-center gap-2">
                      <input
                        type="radio"
                        name="selectedReasons"
                        value="Price for the product has decreased"
                        checked={
                          selectedReason ===
                          "Price for the product has decreased"
                        }
                        onChange={handleChange}
                      />
                      <h6 className="mb-0">
                        Price for the product has decreased
                      </h6>
                    </div>
                    <div className="d-flex align-items-center gap-2">
                      <input
                        type="radio"
                        name="selectedReasons"
                        value="I have purchased the product elsewhere"
                        checked={
                          selectedReason ===
                          "I have purchased the product elsewhere"
                        }
                        onChange={handleChange}
                      />
                      <h6 className="mb-0">
                        I have purchased the product elsewhere
                      </h6>
                    </div>
                    <div className="d-flex align-items-center gap-2">
                      <input
                        type="radio"
                        name="selectedReasons"
                        value="Other"
                        checked={selectedReason === "Other"}
                        onChange={handleChange}
                      />
                      <h6 className="mb-0">Other</h6>
                    </div>
                    <div className="mt-2">
                      <textarea
                        className={`${orderClasses.inputComment} form-control `}
                        rows="5"
                        name="description"
                        onChange={handleDescriptionChange}
                        placeholder={
                          selectedReason === "Other"
                            ? "Enter your comment (Required)"
                            : "Enter your comment (optional)"
                        }
                      />
                    </div>

                    {msg && <p className="text-danger">{msg}</p>}
                  </div>
                </div>
                {uploadedImages?.length < 3 && (
                  <div className="d-flex align-items-center mt-3 mb-1 gap-2">
                    <label
                      className={`${classes.Camera} pointer`}
                      htmlFor="imageInput"
                    >
                      <img src={camera} />
                    </label>
                    <h5
                      className={`m-0 ${classes.CameraText}`}
                      htmlFor="imageInput"
                    >
                      Upload photo
                    </h5>
                    <label
                      className={`${classes.Camera} ms-3 pointer`}
                      onClick={handleCameraUpload}
                    >
                      <img src={camera} />
                    </label>
                    <h5
                      className={`m-0 ${classes.CameraText}`}
                      onClick={handleCameraUpload}
                    >
                      Capture
                    </h5>
                    <input
                      type="file"
                      id="imageInput"
                      accept="image/*"
                      capture="environment"
                      onChange={handleImageUpload}
                      style={{ display: "none" }}
                    />
                  </div>
                )}
                {uploadedImages?.length === 3 && (
                  <p className="m-0 text-danger">
                    *You can upload max of 3 images
                  </p>
                )}
                {uploadedImages?.length > 0 && (
                  <div className={`${classes.ImageBox} mt-3`}>
                    {uploadedImages.map((image, index) => (
                      <div key={index} className={`${classes.ImageReview} `}>
                        <img src={image} alt={`Uploaded ${index + 1}`} />
                        <div className={`${classes.DeleteBox}`}>
                          <i
                            className="bi bi-trash"
                            onClick={() => handleImageRemove(index)}
                          ></i>
                        </div>
                      </div>
                    ))}
                  </div>
                )}

                <div className={`${classes.Text} mt-3`}>
                  <h6 className="m-0">Upload clear photos for easy returns.</h6>
                </div>
                {text && <h6 className="m-0 mb-1 text-danger">{text}</h6>}
                <button
                  className={`${classes.submitBtn} mt-4`}
                  onClick={submitHandler}
                >
                  Submit Review
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <MainFooter />
    </>
  );
};

export default ReturnOrder;
