import { StoreInstance } from "../../Router/AxiosInstance";
import { authActions } from "../Auth/AuthSlice";
import { storeActions } from "./StoreSlice";

export const getStoreLocHandler = () => {
  return async (dispatch) => {
    var result = {
      status: "",
      msg: "",
    };

    var config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `/api/admin/getStoresWithLocation`,
      headers: {
        "Content-Type": "application/json",
         "primarySecId " : process.env.REACT_APP_SWIL_SECRET_KEY,
      },
    };

    await StoreInstance(config)
      .then(function (response) {
        if (response.data.status === "success") {
          result.status = "success";
          dispatch(storeActions.setStoreDetailHandler(response.data.data));
          
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    return result;
  };
};

export const getCurrentStoreLocHandler = (verfiyToken, data) => {
  return async (dispatch) => {
    var result = {
      status: "",
      msg: "",
    };

    var config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "/api/admin/findCurrentStoreLocation",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + verfiyToken,
      },
      data: data,
    };

    await StoreInstance(config)
      .then(function (response) {
        if (response.data.status === "success") {
          result.status = "success";
          console.log(JSON.stringify(response.data));
        }
        // console.log("response get currentlocstores........", JSON.stringify(response.data));
        dispatch(storeActions.setStoreDetailHandler(response.data.data));
      })
      .catch(function (error) {
        console.log(error);
      });
    return result;
  };
};

export const findStoreHandler = (verfiyToken, data1) => {
  let data = JSON.stringify(data1);
  console.log(data)

  return async (dispatch) => {
    var result = {
      status: "",
      msg: "",
    };

    var config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "/api/admin/findStoreByAddress",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + verfiyToken,
      },
      data: data,
    };

    await StoreInstance(config)
      .then(function (response) {
        if (response.data.status === "success") {
          result.status = "success";
          // console.log(JSON.stringify(response.data));
        }
        // console.log("response get currentlocstores........", JSON.stringify(response.data));
        dispatch(storeActions.setStoreDetailHandler(response.data.data));
      })
      .catch(function (error) {
        console.log(error);
        dispatch(storeActions.setStoreDetailHandler(""));
      });
    return result;
  };
};

export const getStoreByIdHandle = (id) => {
  return async (dispatch) => {
    let result = {
      status: "",
      msg: "",
      data: [],
    };

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `/api/admin/getAllStoreLocationByStoreAndLocation?LocationId=${id}`,
    
      headers: {
        "Content-Type": "application/json",
        // Authorization: "Bearer " + verfiyToken,
        "primarySecId " : process.env.REACT_APP_SWIL_SECRET_KEY,
      },
    };

    await StoreInstance(config)
      .then(function (response) {
        if (response.data.status === "success") {
          result.status = "success";
          result.data = response.data.data
        
          dispatch(storeActions.setStoreHandler(response.data.data));
          localStorage.setItem("IsAllProduct", JSON.stringify(response.data.data.IsAllProduct));

        }
      })
      .catch((error) => {
        console.log(error);
        dispatch(storeActions.setStoreHandler());
      });
    return result;
  };
};

export const getStore = (id) => {
 
  return async (dispatch) => {
    let result = {
      status: "",
      msg: "",
      data: [],
    };

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `/api/admin/locationById?LocationId=${id}`,
  
      headers: {
        "Content-Type": "application/json",
        // Authorization: "Bearer " + verfiyToken,
      },
    };

    await StoreInstance(config)
      .then(function (response) {
        if (response.data.status === "success") {
          result.status = "success";
       
          dispatch(storeActions.setStoreHandler(response.data.data));
          dispatch(authActions.setTokenHandler(response.data.data.appKey));
          localStorage.setItem("IsAllProduct", JSON.stringify(response.data.data.IsAllProduct));

        }
      })
      .catch((error) => {
        console.log(error);
        dispatch(storeActions.setStoreHandler());
      });
    return result;
  };
};

export const getAdminStoreHandle = (id) => {
  return async (dispatch) => {
    let result = {
      status: "",
      msg: "",
      data: [],
    };

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `/api/customer/GetStoreOnB2C?StoreId=${id}`,
      headers: {
        "Content-Type": "application/json",
        // Authorization: "Bearer " + verfiyToken,
        "primarySecId " : process.env.REACT_APP_SWIL_SECRET_KEY,

      },
    };

    await StoreInstance(config)
      .then(function (response) {
        if (response.data.message === "Success") {
          result.status = "success";
          dispatch(storeActions.setAdminStoreHandler(response.data.data));
         
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch(storeActions.setAdminStoreHandler());
      });
    return result;
  };
};



