import React, { useEffect, useState } from "react";
import classes from "./Payment.module.css";
import "../../UI/global.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  MessageHandler,
  createOrderHandler,
  createPkidHandler,
  updateOrderHandler,
} from "../../Store/Order/OrderActions";
import { getStoreByIdHandle } from "../../Store/StoreDetail/StoreActions.js";
import {
  LoggedInHandler,
  getCustomerByMobile,
  verifyOtpHandle,
} from "../../Store/Auth/AuthActions";
import { GetAddressHandler } from "../../Store/Address/AddressActions";
import RazorpayButton from "./RazorpayButton.js";

//img path
import MobiQwik from "../../Assets/Images/MobiQwik.png";
import FreeCharge from "../../Assets/Images/FreeCharge.png";
import PhonePe from "../../Assets/Images/PhonePe.png";
import Paytm from "../../Assets/Images/Paytm.png";
import AmazonPay from "../../Assets/Images/AmazonPay.png";
import Razorpay from "../../Assets/Images/Razorpay.png";
import Icici from "../../Assets/Images/Icici.png";
import AxisBank from "../../Assets/Images/AxisBank.png";
import Rupay from "../../Assets/Images/Rupay.png";
import gifImage from "../../Assets/Images/process.gif";

//svg path
import { ReactComponent as ArrowDownSvg } from "../../Assets/SvgComponents/ArrowDownSvg.svg";
import { ReactComponent as VisaSvg } from "../../Assets/SvgComponents/VisaSvg.svg";
import { ReactComponent as CodSvg } from "../../Assets/SvgComponents/CodSvg.svg";
import { ReactComponent as PromoSvg } from "../../Assets/SvgComponents/PromoSvg.svg";
import { ReactComponent as CreditVisaSvg } from "../../Assets/SvgComponents/CreditVisaSvg.svg";
import { ReactComponent as CreditMasterSvg } from "../../Assets/SvgComponents/CreditMasterSvg.svg";
import { ReactComponent as CreditDiscoverSvg } from "../../Assets/SvgComponents/CreditDiscoverSvg.svg";
import { ReactComponent as CreditAESvg } from "../../Assets/SvgComponents/CreditAESvg.svg";
import { ReactComponent as CreditSvg } from "../../Assets/SvgComponents/CreditSvg.svg";
import { cartActions } from "../../Store/Cart/CartSlice";
import { emptyCartHandler } from "../../Store/Cart/CartActions";
import Loader from "../../UI/Loader/Loader";
import { orderActions } from "../../Store/Order/OrderSlice";
import {
  PromotionHandling,
  getInvoicePromoHandler,
} from "../../Store/Product/ProductActions";

import PopUp from "../../UI/PopUp/PopUp";
import noImg from "../../Assets/Images/noImage.png";
import { productActions } from "../../Store/Product/ProductSlice";

const Payment = ({ product, orderById }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedCard, setSelectedCard] = useState("");
  const [loading, setLoading] = useState(false);
  const [promoLoading, setPromoLoading] = useState(false);
  const [productDetail, showProductDetail] = useState(false);
  const totalPrice = useSelector((state) => state.cart.totalPrice);
  const verfiyToken = useSelector((state) => state.auth.verfiyToken);
  const storeById = useSelector((state) => state.store.storeById);
  const Customer = useSelector((state) => state.auth.Customer);
  const AddressKeeper = useSelector((state) => state.address.AddressKeeper);
  const Profile = useSelector((state) => state.auth.Profile);
  const productList = useSelector((state) => state.product.productList);
  const storeId = useSelector((state) => state.store.storeId);
  const promoInvoice = useSelector((state) => state.product.promoInvoice);
  const [promo, SetPromo] = useState();
  const cartList = useSelector((state) => state.cart.cartList);
  const authToken = localStorage.getItem("authToken");

  const handleCardChange = (event) => {
    setSelectedCard(event.target.value);
  };

  const defaultAddress = AddressKeeper.filter(
    (address) => address.default === true
  )[0];

  // function generateOrderId() {
  //   const timestamp = new Date().getTime();
  //   const randomComponent = Math.floor(Math.random() * 10000);
  //   const orderId = `${timestamp}${randomComponent}`;
  //   return orderId;
  // }

  const [locationData, setLocationData] = useState("");
  useEffect(() => {
    dispatch(getStoreByIdHandle(storeId)).then((result) => {
      if (result.status === "success") {
        setLocationData(result.data);
      }
    });
  }, []);


  const orderHandle = () => {
    setLoading(true);
    const data = {
      CustomerID: Customer?._id,
      appKeyToken: verfiyToken,
      CustomerDetail: [
        {
          StoreId: storeById?.StoreId.toString(),
          LocationId: storeById?.LocationId.toString(),
          ERPLocationName: storeById?.LocationName,
          PaymentDetails: {
            COD: {
              cash: false,
            },
            CREDIT_CARD: {
              CreditCard: true,
              CreditCardAmt: 150,
              CreditCardDate: "2023-10-27T11:02:03.464Z",
              CreditCardNo: "5248515",
              CreditCardType: 0,
              CreditDate: "2023-10-29",
            },
            paymentMethod: "CREDIT_CARD",
          },

          couponApplied: "Coupon123",
          payment_date: new Date(),
          payment_status: "Paid",
          transactiondate: new Date(),
          ShippingAddress: {
            Street1: defaultAddress?.addressLine1,
            Street2: defaultAddress?.addressLine2,
            City: defaultAddress?.city,
            State: defaultAddress?.state,
            Country: "India",
            Pincode: defaultAddress?.pincode,
            Mobile: defaultAddress?.mobileNumber,
          },
          Party: Customer?.Username,
          BillingAddress: {
            Street1: defaultAddress?.addressLine1,
            Street2: defaultAddress?.addressLine2,
            City: defaultAddress?.city,
            State: defaultAddress?.state,
            Country: "India",
            Pincode: defaultAddress?.pincode,
            Mobile: defaultAddress?.mobileNumber,
          },
          total_discount: 0.0,
          total_amount: product
            ? product?.SaleRate
            : orderById
            ? orderById?.total_amount
            : totalPrice,
          total_shipping: 0.0,
          total_shipping_discount: 0.0,
          OrderFulfillment: {
            OrderType: "Delivery",
            ExpectedDeliveryTime: "2023-11-02T14:00:00Z",
            CurrentProcessingStatus: "Shipped",
            SuccessDetails: {
              DeliveryTo: defaultAddress?.name,
              PickedUpBy: "Delivery Service",
              TimeDate: "2023-11-02T13:30:00Z",
            },
            tracking_link: "https://example.com/tracking",
          },
          needStatus: "Inprogress",
          created_datetime: new Date(),
          PKID: Customer?.CustomerPKID !== null ? Customer?.CustomerPKID : 0,
          DraftMode: 0,
          ReceivedAmt: product
            ? product?.SaleRate
            : orderById
            ? orderById?.total_amount
            : totalPrice,
          FKPartyID:
            Customer?.CustomerPKID !== null ? Customer?.CustomerPKID : 0,
          // FKPartyID: 10000009,
          // FKSeriesID: "3",
          FKSeriesID:storeById?.PKOrderSeriesId,
          AutoAddCustomer:
            Customer?.CustomerPKID === null || Customer?.CustomerPKID === 0
              ? true
              : false,
          ApplyPromotion: promo ? true : false,
          ProdDetail: promo
            ? promo?.ProdDtl
            : product
            ? [
                {
                  Product_Id: product?._id,
                  Product_name: product?.NameToDisplay,
                  Product_detail: product?.Description,
                  ProductStore_Id: storeById?.StoreId,
                  SrNo: 1,
                  FKProdID: 10000017,
                  // MRP: product?.SaleRate,
                  Qty: 1,
                  Rate: product?.SaleRate,
                },
              ]
            : orderById
            ? orderById.ProdDetail
            : cartList?.map((item, index) => ({
                Product_Id: item.Product_Id,
                Product_name: item?.Product_name,
                Product_detail: item?.Product_detail,
                ProductStore_Id: storeById?.StoreId,
                FKProdID: item.FKProdID,
                // MRP: item.Product_Price,
                Qty: item.Product_Quantity,
                Rate: item.Product_Price,
                SrNo: index + 1,
              })),
        },
      ],
    };
    dispatch(createOrderHandler(authToken, data)).then((result) => {
      if (result.status === "success") {
        navigate(`/${storeId}/orderSuccess`);
        setLoading(false);
        if (!product && !orderById) {
          dispatch(cartActions.setCartList(""));
          dispatch(emptyCartHandler(authToken, Profile?.MobileNumber));
        }
      }
    });
  };

  const handleRazorpayClick = () => {
    <>
      <RazorpayButton
        totalPrice={
          product
            ? product?.SaleRate
            : orderById
            ? orderById?.total_amount
            : totalPrice
        }
      />
    </>;
  };
  const promoHandling = () => {
    setPromoLoading(true);
    dispatch(
      PromotionHandling(storeById?.StoreId, {
        ProdDtl: product
          ? [
              {
                Product_Id: product?._id,
                Product_name: product?.NameToDisplay,
                Product_detail: product?.Description,
                ProductStore_Id: storeById?.StoreId,
                SrNo: 1,
                FKProdID: product?.PKID,
                // MRP: product?.SaleRate,
                Qty: 1,
                Rate: product?.SaleRate,
              },
            ]
          : orderById
          ? orderById.ProdDetail
          : cartList?.map((item, index) => ({
              Product_Id: item.Product_Id,
              Product_name: item?.Product_name,
              Product_detail: item?.Product_detail,
              ProductStore_Id: storeById?.StoreId,
              FKProdID: item.FKProdID,
              // MRP: item.Product_Price,
              Qty: item.Product_Quantity,
              Rate: item.Product_Price,
              SrNo: index + 1,
            })),
        CashDisc: 0,
        CashDiscType: "U",
        FKPartyID: Customer?.CustomerPKID !== null ? Customer?.CustomerPKID : 0,
        FKSeriesID: "3",
        ApplyPromotion: true,
      })
    ).then((result) => {
      if (result.status === "success") {
        SetPromo(result.data);

        setPromoLoading(false);
      }
    });
  };

  const promoInvoiceHandling = () => {
    if (promoInvoice?.length > 0) {
      setPromoLoading(true);
      dispatch(
        PromotionHandling(storeById?.StoreId, {
          ProdDtl: product
            ? [
                {
                  Product_Id: product?._id,
                  Product_name: product?.NameToDisplay,
                  Product_detail: product?.Description,
                  ProductStore_Id: storeById?.StoreId,
                  SrNo: 1,
                  FKProdID: product?.PKID,
                  // MRP: product?.SaleRate,
                  Qty: 1,
                  Rate: product?.SaleRate,
                },
              ]
            : orderById
            ? orderById.ProdDetail
            : cartList?.map((item, index) => ({
                Product_Id: item.Product_Id,
                Product_name: item?.Product_name,
                Product_detail: item?.Product_detail,
                ProductStore_Id: storeById?.StoreId,
                FKProdID: item.FKProdID,
                // MRP: item.Product_Price,
                Qty: item.Product_Quantity,
                Rate: item.Product_Price,
                SrNo: index + 1,
              })),
          CashDisc: 0,
          CashDiscType: "U",
          FKPartyID:
            Customer?.CustomerPKID !== null ? Customer?.CustomerPKID : 0,
          FKSeriesID: "3",
          ApplyPromotion: true,
        })
      ).then((result) => {
        if (result.status === "success") {
          SetPromo(result.data);

          setPromoLoading(false);
        }
      });
    }
  };

  useEffect(() => {
    if (authToken) {
      dispatch(LoggedInHandler(authToken)).then((result) => {
        if (result.status === "success") {
          dispatch(getCustomerByMobile(verfiyToken, result.data.mobile));
          dispatch(GetAddressHandler(authToken, result.data.id));
        }
      });
    }
  }, []);

  useEffect(() => {
    dispatch(getInvoicePromoHandler(storeById?.StoreId)).then((result) => {
      if (result.status === "success") {
        dispatch(
          productActions.setPromoInvoiceHandler(result.data[0]?.invoice)
        );
      }
    });
  }, [dispatch, verfiyToken]);

  return (
    <>
      {loading === false ? (
        <div className={` ${classes.payHeight} `}>
          {productDetail && (
            <PopUp
              title="Product Details"
              content={
                <div className="d-flex  flex-column">
                  {" "}
                  {promo?.ProdDtl?.map((order, index) => {
                    const correspondingProduct = productList.find(
                      (item) => item.PKID === order.FKProdID
                    );
                    return (
                      <div
                        className={`${classes.CancelOrder} col-12`}
                        key={index}
                      >
                        <div
                          className={`${classes.Product} ${classes.productCont}`}
                        >
                          <h6> {correspondingProduct?.NameToDisplay}</h6>

                          <p>
                            Qty : <span className="fw-bold">{order?.Qty}</span>{" "}
                          </p>
                          <p
                            className={`${classes.OrderDiscountText} fw-bold text-success`}
                          >
                            Discount :{" "}
                            <span className="text-success">
                              {order?.TradeDisc > 0 ? (
                                `${order?.TradeDisc}%`
                              ) : (
                                <span className="text-danger">N/A</span>
                              )}
                            </span>{" "}
                          </p>

                          {order?.TradeDisc > 0 ? (
                            <div>
                              <span className={`${classes.Rupee} `}>₹ </span>
                              <span className={`${classes.Amount}`}>
                                {order.Rate -
                                  (order.Rate * order?.TradeDisc) / 100}
                              </span>{" "}
                              <del className={`${classes.PreviousAmount} ms-2`}>
                                ₹{order.Rate}
                              </del>
                            </div>
                          ) : (
                            <div>
                              <span className={`${classes.Rupee} `}>₹ </span>
                              <span className={`${classes.Amount}`}>
                                {order.Rate}
                              </span>
                            </div>
                          )}
                        </div>
                        <div
                          className={`${classes.ProductBreakupImage} d-flex align-items-center`}
                        >
                          {correspondingProduct?.Images?.length > 0 &&
                          correspondingProduct?.Images ? (
                            <img
                              variant="top"
                              src={correspondingProduct?.Images[0]}
                              className={`${classes.OrderImage}`}
                              alt="img"
                            />
                          ) : (
                            <img
                              src={noImg}
                              alt="img"
                              className={`${classes.OrderImage}`}
                            />
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              }
              onClose={() => showProductDetail(!productDetail)}
              order={true}
            />
          )}
          <div className="container-fluid">
            <div className={`${classes.address} row`}>
              <div className="col-12 ">
                <div className={`${classes.addressHead} mt-3 `}>
                  <p>SAVED CARDS</p>
                  <p
                    className={`${classes.addressAddTxt} `}
                    data-bs-toggle="modal"
                    data-bs-target="#staticBackdrop1"
                  >
                    Add{" "}
                  </p>
                </div>
                <div className="d-flex flex-column gap-4">
                  <div className="d-flex justify-content-between align-items-center ">
                    <div className="d-flex align-items-center gap-2 ">
                      <img src={AxisBank} alt="axis" className="img-fluid" />
                      <div
                        className={`${classes.AddMain} d-flex flex-column ms-2`}
                      >
                        <p>Axis Bank</p>
                        <div
                          className={`${classes.CardNumber} d-flex align-items-center gap-2`}
                        >
                          <h6>XXXX 4002 </h6>
                          <VisaSvg />
                        </div>
                      </div>
                    </div>
                    <input
                      type="radio"
                      name="cards"
                      value="card1"
                      checked={selectedCard === "card1"}
                      onChange={handleCardChange}
                    />
                  </div>
                  <div className="d-flex justify-content-between align-items-center ">
                    <div className="d-flex align-items-center gap-2 ">
                      <img src={Icici} alt="icici" className="img-fluid" />
                      <div
                        className={`${classes.AddMain} d-flex flex-column ms-2`}
                      >
                        <p>ICICI Bank</p>
                        <div
                          className={`${classes.CardNumber} d-flex align-items-center gap-2`}
                        >
                          <h6>XXXX 4002</h6>
                          <VisaSvg />
                        </div>
                      </div>
                    </div>
                    <input
                      type="radio"
                      name="cards"
                      value="card2"
                      checked={selectedCard === "card2"}
                      onChange={handleCardChange}
                    />
                  </div>
                </div>
              </div>
              <div className="col-12 mt-2">
                <div className={`${classes.addressHead} `}>
                  <p>WALLETS</p>
                  <ArrowDownSvg />
                </div>
                <div className={`${classes.UpiApps} d-flex gap-3`}>
                  {/* <img src={Razorpay } onClick={handleRazorpayClick} alt="Clickable" className="img-fluid"   /> */}
                  <RazorpayButton totalPrice={1 * 100} />
                  {/* <RazorpayButton totalPrice = {product
            ? product?.SaleRate
            : orderById
            ? orderById?.total_amount
            : totalPrice * 100}/> */}
                  {/* <img src={Paytm} alt="paytm" className="img-fluid" />
                  <img src={PhonePe} alt="phonepe" className="img-fluid" />
                  <img
                    src={FreeCharge}
                    alt="freecharge"
                    className="img-fluid"
                  />
                  <img src={MobiQwik} alt="mobiqwik" className="img-fluid" /> */}
                </div>
                <div className="d-flex justify-content-between align-items-center mt-4 ">
                  <div className="d-flex align-items-center gap-2 ">
                    <CodSvg />
                    <div className={`${classes.Cod} d-flex flex-column ms-2`}>
                      <div className="d-flex align-items-center gap-2">
                        <h6>Cash on Delivery</h6>
                      </div>
                      {/* <p>Nominal fee of 5 cents will be Charged</p> */}
                    </div>
                  </div>
                  <input
                    type="radio"
                    name="cards"
                    value="card3"
                    checked={true}
                    onChange={handleCardChange}
                  />
                </div>
              </div>
              <div className={`${classes.dropBox} dropBox dropdown`}>
                {/* <button className="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
    Dropdown button
  </button> */}
                <div
                  className={`${classes.menu}  p-0 pb-2 `}
                  data-bs-toggle={promo ? "" : "dropdown"}
                  aria-expanded="false"
                >
                  <div
                    className={`${classes.PromoBox} pointer`}
                    // onClick={() => promoHandling()}
                  >
                    <div className="d-flex gap-3">
                      {promoLoading === true ? (
                        <div className={`${classes.Load}`}>
                          {" "}
                          <Loader />{" "}
                        </div>
                      ) : (
                        <PromoSvg />
                      )}
                      {promo ? (
                        <div
                          className={`${classes.menuContent}`}
                          onClick={() => SetPromo()}
                        >
                          Remove promo code
                        </div>
                      ) : (
                        <div className={`${classes.menuContent} `}>
                          Apply promo code
                        </div>
                      )}
                    </div>
                    <div>
                      <i className={` bi bi-chevron-right`}></i>
                    </div>
                  </div>
                </div>
                <ul className="dropdown-menu">
                  <li>
                    <div
                      className="dropdown-item pointer"
                      onClick={() => promoHandling()}
                    >
                      Trade Discount (%)
                    </div>
                  </li>
                  <li>
                    <div
                      className="dropdown-item pointer"
                      onClick={() => promoInvoiceHandling()}
                    >
                      Invoice Value
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className=" mt-2 ">
            <div className={`${classes.addressHead} ${classes.PriceBorder} `}>
              <p>PRICE DETAILS</p>
              {promo ? (
                <div
                  className={`pe-2 ${classes.summary}`}
                  onClick={() => showProductDetail(true)}
                >
                  View Breakup
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="container-fluid ">
              <div className={`${classes.PaymentPricing}`}>
                <p>Price</p>
                <p>
                  ₹
                  {product
                    ? product?.SaleRate
                    : orderById
                    ? orderById?.total_amount
                    : totalPrice}
                </p>
              </div>

              {promo ? (
                <div className={`${classes.PaymentPricing}`}>
                  <p>Discount</p>
                  <p>
                    {promo?.TotalDisc > 0 ? (
                      `- ₹${promo?.TotalDisc}`
                    ) : (
                      <p className="text-danger">N/A</p>
                    )}
                  </p>
                </div>
              ) : (
                ""
              )}

              <div className={`${classes.PaymentPricing}`}>
                <p>Delivery Charges</p>
                <p className={`${classes.freeText}`}>FREE</p>
              </div>
            </div>
            <div className={`${classes.PriceBorder}`}></div>
            <div className="container-fluid ">
              <div className={`${classes.PaymentTotal}`}>
                <h6>Total Items</h6>
                <h6>
                  {product
                    ? 1
                    : orderById
                    ? orderById?.ProdDetail?.length
                    : cartList?.length}
                </h6>
              </div>
              <div className={`${classes.PaymentTotal}`}>
                <h6>Total payable</h6>
                {promo ? (
                  <h6>₹{promo?.ReceivedAmt}</h6>
                ) : (
                  <h6>
                    ₹
                    {product
                      ? product?.SaleRate
                      : orderById
                      ? orderById?.total_amount
                      : totalPrice}
                  </h6>
                )}
              </div>
            </div>
          </div>
          <div className={`${classes.creditDetail}  container-fluid`}>
            <CreditVisaSvg />
            <CreditMasterSvg />
            {/* <CreditRupaySvg/> */}
            <img src={Rupay} alt="rupay" className={`${classes.PayImage}`} />
            <CreditDiscoverSvg />
            <CreditAESvg />
            <CreditSvg />
          </div>
          <div
            className={`${classes.footer}  d-flex justify-content-between align-items-center container`}
          >
            <div className="d-flex flex-column mt-3">
              {promo ? (
                <div className={`${classes.CartPay}`}>
                  {" "}
                  <span className={`${classes.previousCartAmount} `}>
                    <del>₹{promo?.GrossAmt}</del>
                  </span>
                </div>
              ) : (
                ""
              )}

              {promo ? (
                <p className={`${classes.amount} `}>
                  ₹{promo?.ReceivedAmt}
                  <span
                    className={`${classes.paymentDetailText} pointer ms-1`}
                    onClick={() => showProductDetail(true)}
                  >
                    Details
                  </span>{" "}
                </p>
              ) : (
                <p className={`${classes.amount} `}>
                  ₹
                  {product
                    ? product?.SaleRate
                    : orderById
                    ? orderById?.total_amount
                    : totalPrice}
                  {/* <span className={`${classes.paymentDetailText} ms-1`}>
                  Details
                </span>{" "} */}
                </p>
              )}
            </div>
            <div className="d-flex gap-3">
              <button
                type="button"
                className={`${classes.applyBtn} ${classes.Back} btn btn-primary`}
                onClick={() => dispatch(orderActions.setCurrentStepHandler(2))}
              >
                Back
              </button>
              <button
                type="button"
                className={` ${classes.applyBtn} btn btn-primary`}
                onClick={orderHandle}
              >
                Pay Now{" "}
              </button>
            </div>
          </div>

          <div
            className={`${classes.creditModal} modal  fade`}
            id="staticBackdrop1"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog  modal-dialog-scrollable">
              <div className={`modal-content ${classes.paymentModalContent} `}>
                <div
                  className={`modal-header ${classes.paymentModalHeader} ${classes.creditAddHeader}`}
                >
                  <h6>Add credit card</h6>
                  <div className={`${classes.FilterHeader} `}>
                    <button
                      type="button"
                      className="btn-close "
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                </div>
                <div className={`modal-body ${classes.paymentModalBody}`}>
                  <form className={`${classes.addressForm} mx-auto`}>
                    <div className="mb-3  position-relative">
                      <label className="form-label ">CARD NUMBER</label>
                      <input
                        type="text"
                        className={`form-control ${classes.AddressInput}`}
                        name="number"
                        // value=""
                        placeholder="1234 1234 1234 1234"
                      />
                    </div>
                    <div className="mb-3   position-relative">
                      <label className="form-label ">CARD HOLDER NAME</label>
                      <input
                        type="text"
                        className={`form-control ${classes.AddressInput}`}
                        name="name"
                        // value=""
                        placeholder="enter full name"
                      />
                    </div>
                    <div className="d-flex gap-3">
                      <div className="mb-3   position-relative">
                        <label className="form-label ">EXPIRY DATE</label>
                        <input
                          type="text"
                          className={`form-control ${classes.AddressInput}`}
                          name="date"
                          // value=""
                          placeholder="1234 1234 1234 1234"
                        />
                      </div>
                      <div className="mb-3   position-relative">
                        <label className="form-label ">CVV</label>
                        <input
                          type="text"
                          className={`form-control ${classes.AddressInput}`}
                          name="cvv"
                          // value=""
                          placeholder="852"
                        />
                      </div>
                    </div>
                    <div className={`form-check ${classes.CheckLabel}`}>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        // value=""
                        id="flexCheckDefault"
                      />
                      <label className={`form-check-label `}>Save Card</label>
                    </div>
                  </form>

                  <div className="py-4 px-2 ">
                    <button
                      type="button"
                      className={` ${classes.applyBtn}  btn btn-primary `}
                      style={{ width: "100%" }}
                    >
                      Proceed{" "}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        // <div className={`${classes.LoaderSet}`}>
        //   <Loader />
        // </div>
        <div className={`${classes.noDataContainer}`}>
          <div className={`${classes.LoaderSet}`}>
            <Loader />
          </div>

          <img
            src={gifImage}
            alt="No data GIF"
            className={`${classes.gifImage}`}
          />
        </div>
      )}
    </>
  );
};

export default Payment;
