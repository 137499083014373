import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Routes, Route } from "react-router-dom";
import StoreHome from "../Pages/Home/StoreHome";
import Notification from "../Pages/Notification/Notification";
import Wishlist from "../Pages/WishList/Wishlist";
import AllCategory from "../Pages/AllCategory/AllCategory";
import Cart from "../Pages/Cart/Cart";
import ProductDetail from "../Pages/ProductDetail/ProductDetail";
import Profile from "../Pages/Profile/Profile";
import EditProfile from "../Pages/Profile/EditProfile";
import Address from "../Pages/Address/Address";
import ManageAddress from "../Pages/Address/ManageAddress";
import Payment from "../Pages/Payments/Payment";
import SearchProduct from "../Pages/SearchProduct/SearchProduct";
import Verification from "../Pages/Profile/Verification";
import VerificationOtp from "../Pages/Profile/VerificationOtp";
import OrderSuccess from "../Pages/Order/OrderSuccess";
import OrderSuccessInfo from "../Pages/Order/OrderSuccessInfo";
import CancelOrder from "../Pages/Order/CancelOrder";
import Order from "../Pages/Order/Order";
import MyOrder from "../Pages/Order/MyOrder";
import OrderSummary from "../Pages/Order/OrderSummary";
import ProductCheckout from "../Pages/Payments/ProductCheckout";
import Home from "../Pages/Home/Home";
import Mart from "../Pages/Mart/Mart";
import OrderSteps from "../Pages/Order/OrderSteps";
import ProductDemo from "../Pages/SearchProduct/ProductDemo";
import SubmitReview from "../Pages/Review/SubmitReview";
import ReviewMain from "../Pages/Review/ReviewMain";
import ReturnOrder from "../Pages/Order/ReturnOrder";
import DetailPage from "../UI/Footer/DetailPage";
import { getStore } from "../Store/StoreDetail/StoreActions";

const RouteHandler = () => {
  const dispatch = useDispatch();
  const verfiyToken = useSelector((state) => state.auth.verfiyToken);
  const storeId = useSelector((state) => state.store.storeId);

  useEffect(() => {
    if (storeId && !verfiyToken) {
      dispatch(getStore(storeId));
    }
  }, [storeId, verfiyToken, dispatch]);

  return (
    <Routes>
      <Route path="/" element={<Mart />} />
      <Route path="/storeSelect" element={<Home />} />
      <Route path="/:id/home" element={<StoreHome />} />
      <Route path="/notification" element={<Notification />} />
      <Route path="/:id/wishlist" element={<Wishlist />} />
      <Route path="/:id/allcategory" element={<AllCategory />} />
      <Route path="/:id/allcategory/:categoryId" element={<AllCategory />} />
      <Route path="/:id/cart" element={<Cart />} />
      <Route path="/:id/productDetail/:name/:productId" element={<ProductDetail />} />
      <Route path="/:id/profile" element={<Profile />} />
      <Route path="/:id/editprofile" element={<EditProfile />} />
      <Route path="/verification" element={<Verification />} />
      <Route path="/verifyOtp" element={<VerificationOtp />} />
      <Route path="/:id/address" element={<Address />} />
      <Route path="/:id/manageaddress" element={<ManageAddress />} />
      <Route path="/payment" element={<Payment />} />
      <Route path="/:id/searchProduct" element={<SearchProduct />} />
      <Route path="/:id/searchProduct/:brandId" element={<SearchProduct />} />
      <Route path="/:id/orderSuccess" element={<OrderSuccess />} />
      <Route path="/:id/orderSuccessInfo" element={<OrderSuccessInfo />} />
      <Route path="/:id/cancelOrder" element={<CancelOrder />} />
      <Route path="/:id/ReturnOrder" element={<ReturnOrder />} />
      <Route path="/:id/ReturnOrder/:orderId" element={<ReturnOrder />} />
      <Route path="/:id/cancelOrder/:orderId" element={<CancelOrder />} />
      <Route path="/:id/order" element={<Order />} />
      <Route path="/:id/order/:orderId" element={<Order />} />
      <Route path="/:id/MyOrder" element={<MyOrder />} />
      <Route path="/:id/OrderSummary" element={<OrderSummary />} /> 
      <Route path="/:id/OrderSummary/:orderId" element={<OrderSummary />} />
      <Route path="/ProductCheckout" element={<ProductCheckout />} />
      <Route path="/:id/orderSteps" element={<OrderSteps />} />
      <Route path="/:id/orderSteps/:orderId" element={<OrderSteps />} />
      <Route path="/:id/ProductDemo" element={<ProductDemo />} />
      <Route path="/:id/SubmitReview" element={<SubmitReview />} />
      <Route path="/:id/SubmitReview/:productId" element={<SubmitReview />} />
      <Route path="/:id/Review" element={<ReviewMain />} /> 
      <Route path="/:id/Details" element={<DetailPage />} />
    </Routes>
  );
};

export default RouteHandler;
