import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import classes from "./Home.module.css";
import "../../UI/global.css";
import Category from "./Category";
import Brand from "./Brand";
import Slider from "./Slider";
import Footer from "../../UI/Footer/Footer";
import {
  getAllPromotionHandler,
  getInvoicePromoHandler,
} from "../../Store/Product/ProductActions";
import { useDispatch, useSelector } from "react-redux";
import { getCategoryHandler } from "../../Store/Category/CategoryActions";

import { getBrandHandler } from "../../Store/Brand/BrandActions";
import Product from "./Product";
import { GetAddressHandler } from "../../Store/Address/AddressActions";

import {
  LoggedInHandler,
  getProfileHandle,
} from "../../Store/Auth/AuthActions";
import {
  AddLocalCartHandler,
  getCartHandler,
} from "../../Store/Cart/CartActions";
import { cartActions } from "../../Store/Cart/CartSlice";

import MainHeader from "./MainHeader";
import MainFooter from "../../UI/Footer/MainFooter";
import BackToTop from "../../UI/BackToTop/BackToTop";
import {
  getStore,
  getStoreByIdHandle,
} from "../../Store/StoreDetail/StoreActions";
import { storeActions } from "../../Store/StoreDetail/StoreSlice";

const Home = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authToken = localStorage.getItem("authToken");
  const topRef = useRef(null);
  const verfiyToken = useSelector((state) => state.auth.verfiyToken);
  const cartList = useSelector((state) => state.cart.cartList);
  const cartData = useSelector((state) => state.cart.cartData);
  const mainCategory = useSelector((state) => state.category.mainCategory);
  const storeById = useSelector((state) => state.store.storeById);
  const [isVisible, setIsVisible] = useState(false);
  const [querySet, setQuerySet] = useState(true);


  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        setIsVisible(!entry.isIntersecting);
      },
      { threshold: 0.1 }
    );

    if (topRef.current) {
      observer.observe(topRef.current);
    }

    return () => {
      if (topRef.current) {
        observer.unobserve(topRef.current);
      }
    };
  }, []);

  const handleScrollUp = () => {
    if (topRef.current) {
      topRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(
    () => {
      dispatch(getCategoryHandler(storeById?.StoreId));
      dispatch(getBrandHandler(storeById?.StoreId));
      dispatch(getInvoicePromoHandler(storeById?.StoreId));
      dispatch(getAllPromotionHandler(storeById?.StoreId));
    },
    [dispatch, storeById, verfiyToken],
    authToken
  );

  useEffect(() => {
    if (authToken) {
      dispatch(LoggedInHandler(authToken)).then((result) => {
        if (result.status === "success") {
          const data = {
            User_Numberid: result.data.mobile,
          };
          dispatch(getProfileHandle(verfiyToken, data.User_Numberid));
          dispatch(GetAddressHandler(authToken, result.data.id));
          if (authToken && cartData.length > 0) {
            dispatch(
              AddLocalCartHandler(
                verfiyToken,
                authToken,
                cartData,
                data.User_Numberid
              )
            ).then((result) => {
              if (result.status === "success") {
                dispatch(cartActions.setLocalCartList());

                dispatch(cartActions.setLocalCartList());
                dispatch(
                  getCartHandler(verfiyToken, authToken, data.User_Numberid)
                );
              }
            });
          }
        }
      });
    }
  }, []);

  useEffect(() => {
    if (id && !verfiyToken) {
      dispatch(getStore(id));
    }
  }, [id, dispatch, verfiyToken]);

  useEffect(() => {
    if (id) {
      dispatch(storeActions.setStoreId(id));
    }
  }, [id, dispatch]);

  useEffect(() => {
    dispatch(getStoreByIdHandle(id));
  }, [id, dispatch]);

  console.log("cartData--------", cartData)
  return (
    <div className={classes.homeMainWrapper}>
      {isVisible && <BackToTop handleScrollUp={handleScrollUp} />}
      <MainHeader cartData={cartData} cartList={cartList} />
      <section className={classes.slider} ref={topRef}>
        <Slider />
      </section>
      <section className={classes.category}>
        <Category />
      </section>
      <section className={classes.brand}>
        <Brand />
      </section>
      <section className={` ${classes.tabcomp}`}>
        <div className={`${classes.productWrapper}   container-fluid `}>
          {mainCategory

            .filter((category) => category.display)
            .map((category, index) => (
              <div key={index}>
                <div className={`${classes.headline} overflow-hidden `}>
                  <h6 className="pb-1 position-relative d-inline-block">
                    {category.nameDisplay}
                  </h6>
                </div>
                <Product querySet={querySet} category={category._id} />
              </div>
            ))}
        </div>
      </section>
      <section className={`mt-0 ${classes.tabcomp}`}>
        <div className={`${classes.productWrapper}   container-fluid `}>
          <div className={`${classes.headline}   overflow-hidden `}>
            <h6 className="pb-1 position-relative d-inline-block">
              Latest Products
            </h6>
          </div>
          <Product querySet={querySet} />
        </div>
      </section>

      {authToken ? (
        <>
          {cartList?.length > 0 ? (
            <Footer onClick={() => navigate(`/${id}/cart`)} />
          ) : (
            ""
          )}
        </>
      ) : (
        <>
          {cartData?.length > 0 ? (
            <Footer onClick={() => navigate(`/${id}/cart`)} />
          ) : (
            ""
          )}
        </>
      )}
      <MainFooter />
      {/* {cartData?.length > 0 ? <Footer onClick={() => navigate("/cart")} /> : ""} */}
    </div>
  );
};

export default Home;
