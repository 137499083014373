import React, { useEffect, useState } from "react";
import Header from "../../UI/Header/Header";
import { useNavigate,useParams,useLocation } from "react-router-dom";
import classes from "./Cart.module.css";
import { useDispatch, useSelector } from "react-redux";
import { cartActions } from "../../Store/Cart/CartSlice";
import { getProductByIdHandler } from "../../Store/Product/ProductActions";
import AddToCart from "../../UI/AddToCart/AddToCart";
import { orderActions } from "../../Store/Order/OrderSlice";

import Loader from "../../UI/Loader/Loader";

import {
  AddLocalCartHandler,
  deleteCartHandler,
  getCartHandler,
  AddCartHandler,
} from "../../Store/Cart/CartActions";
import {
  PostWishlistHandler,
  RemoveWishlistHandler,
  
  getWishlistHandler,
} from "../../Store/Product/ProductActions";

import { GetAddressHandler } from "../../Store/Address/AddressActions";
import { LoggedInHandler } from "../../Store/Auth/AuthActions";
import {
  AddApprovalHandler,
  getCustomerApprovalHandler,
  getStoreApprovalHandler,
} from "../../Store/Order/OrderActions";
import MainHeader from "../Home/MainHeader";
import MainFooter from "../../UI/Footer/MainFooter";
import noImg from "../../Assets/Images/noImage.png";
import { ReactComponent as GeoLocSvg } from "../../Assets/SvgComponents/GeoLocSvg.svg";
import { ReactComponent as CartImage1 } from "../../Assets/SvgComponents/cartImage1.svg";
import { ReactComponent as CartImage2 } from "../../Assets/SvgComponents/cartImage2.svg";
import { ReactComponent as CartImage3 } from "../../Assets/SvgComponents/cartImage3.svg";
import { ReactComponent as CartSvg } from "../../Assets/SvgComponents/CartSvg.svg";

import EmptyCart from "../../Assets/Images/EmptyCart.png";
import { authActions } from "../../Store/Auth/AuthSlice";
import OrderSteps from "../Order/OrderSteps";
import { getStoreByIdHandle } from "../../Store/StoreDetail/StoreActions";
import PopUp from "../../UI/PopUp/PopUp";
import { storeActions } from "../../Store/StoreDetail/StoreSlice";

const Cart = (props) => {
  const {id} = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const cartList = useSelector((state) => state.cart.cartList);
  const cartData = useSelector((state) => state.cart.cartData);
  const totalPrice = useSelector((state) => state.cart.totalPrice);
  const cartPrice = useSelector((state) => state.cart.cartPrice);
  const AddressKeeper = useSelector((state) => state.address.AddressKeeper);
  const verfiyToken = useSelector((state) => state.auth.verfiyToken);
  const Profile = useSelector((state) => state.auth.Profile);
  const storeById = useSelector((state) => state.store.storeById);
  const productList = useSelector((state) => state.product.productList);
  const wishlist = useSelector((state) => state.product?.wishlist);

  const [order, setOrder] = useState(false);
  const [processed, setProcessed] = useState(false);
  const [error, showError] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [msg, setMsg] = useState("");
  
  const [loading, setLoading] = useState(false)

  const [productDel, setProduct] = useState(null)

  useEffect(() => {
    if (id) {
      dispatch(storeActions.setStoreId(id));
    }
  }, [id, dispatch]);

  const withinPromotionTime = (fromTime, toTime) => {
    const currentTime = new Date();
    const currentHours = currentTime.getHours();
    const currentMinutes = currentTime.getMinutes();

    const fromTimeParts = fromTime && fromTime.split(":");
    const toTimeParts = toTime && toTime.split(":");

    const fromHours = fromTimeParts && parseInt(fromTimeParts[0], 10);
    const fromMinutes = fromTimeParts && parseInt(fromTimeParts[1], 10);

    const toHours = toTimeParts && parseInt(toTimeParts[0], 10);
    const toMinutes = toTimeParts && parseInt(toTimeParts[1], 10);

    if (
      toHours > fromHours ||
      (toHours === fromHours && toMinutes >= fromMinutes)
    ) {
      return (
        (currentHours > fromHours ||
          (currentHours === fromHours && currentMinutes >= fromMinutes)) &&
        (currentHours < toHours ||
          (currentHours === toHours && currentMinutes <= toMinutes))
      );
    } else {
      return (
        currentHours > fromHours ||
        (currentHours === fromHours && currentMinutes >= fromMinutes) ||
        currentHours < toHours ||
        (currentHours === toHours && currentMinutes <= toMinutes)
      );
    }
  };

  const addToWishlist = (WishlistProductId, check) => {
   
    const isRemove = check === "remove";
    const FKProdID = isRemove ? WishlistProductId?.FKProdID : WishlistProductId;

    if (!FKProdID) {
     return;
    }

    const data = {

      WishlistProductId: FKProdID,
      User_Number: Profile?.MobileNumber,
      appKeyToken: verfiyToken,
    };

    if (authToken) {
      setLoading(true);
      dispatch(LoggedInHandler(authToken)).then((result) => {
        if (result.status === "success") {
          dispatch(PostWishlistHandler(authToken, data)).then((result) => {
            if (result.status === "success") {
              dispatch(
                getWishlistHandler(
                  authToken,
                  { appKeyToken: verfiyToken },
                  Profile?.MobileNumber
                )
              );
              if (check === "remove") {
                deleteProdHandle(WishlistProductId)
                setLoading(false)
              }
            } else {
              navigate("/verification");
              dispatch(authActions.setFromPage("cart"));
              setLoading(false)
            }
          });
        }
      });
    } else {
      navigate("/verification");
      dispatch(authActions.setFromPage("cart"));
    }
  };

  const removeWishlist = (id) => {
    dispatch(
      RemoveWishlistHandler(authToken, Profile?.MobileNumber, id, {
        appKeyToken: verfiyToken,
      })
    ).then((result) => {
      if (result.status === "success") {
        dispatch(
          getWishlistHandler(
            authToken,
            { appKeyToken: verfiyToken },
            Profile?.MobileNumber
          )
        );
      } else {
        navigate("/verification");
        dispatch(authActions.setFromPage("cart"));
      }
    });
  };

  const addCart = (product) => {
    const data = {
      Product_Id: product?._id,
      Product_name: product?.NameToDisplay,
      Product_Quantity: 1,
      FKProdID: product?.PKID,
     Product_detail: product?.Description,
      Product_Price: product?.SaleRate,
     User_Numberid: Profile?.MobileNumber,
      Product_Image: product?.Images?.length > 0 ? product?.Images[0] : null,
    };

    if (authToken) {
      dispatch(
        AddCartHandler(verfiyToken, authToken, data, data.User_Numberid)
      ).then((result) => {
        if (result.status === "success") {
          dispatch(getCartHandler(verfiyToken, authToken, data.User_Numberid));
        }
      });
    } else {
      dispatch(
        cartActions.pushProToCartListHandler({
          Product_Id: product._id,
          FKProdID: product.PKID,
         Product_name: product.NameToDisplay,
          Product_Quantity: 1,
          Product_detail: product.Description,
          Product_Price: product.SaleRate,
          User_Numberid: Profile?.MobileNumber,
     })
      );
      dispatch(cartActions.calculateTotalLocalPriceHandler(product));
    }
  };

  const authToken = localStorage.getItem("authToken");

  const cartContent = authToken ? cartList : cartData;

  const defaultAddress = AddressKeeper?.filter(
    (address) => address.default === true
  )[0];



  const deleteProdHandle = (product) => {
    if (!product || !product.Product_Id) {
      return;
    }

    const data = {
      Product_Id: product.Product_Id,
      User_Numberid: Profile?.MobileNumber,

    };
   if (authToken) {
      dispatch(
        deleteCartHandler(authToken, data.User_Numberid, product.Product_Id)
      ).then((result) => {
        if (result.status === "success") {
          dispatch(getCartHandler(verfiyToken, authToken, data.User_Numberid));
        }
      });
    } else {
      dispatch(cartActions.removeProductHandler(product));
      if (cartData?.length < 2) {
        dispatch(cartActions.setLocalCartList());
      }
      dispatch(cartActions.calculateTotalLocalPriceHandler(product));
   }
    setConfirmDelete(true)

   };

  const ProductDataHandler = (product) => {
    const modifiedName = product.Product_name?.replace(/ /g, "-")
      .replace(/'/g, "")
      .replace(/:/g, "-")
      .replace(/\//g, "-");
    const encodedName = encodeURIComponent(modifiedName);
    dispatch(getProductByIdHandler(storeById?.StoreId, product.Product_Id));
    navigate(`/${id}/productdetail/${encodedName}/${product.Product_Id}`);
  };

  const ProductDataLikeHandler = (product) => {
    const modifiedName = product?.NameToDisplay?.replace(/ /g, "-")
        .replace(/'/g, "")
        .replace(/:/g, "-")
        .replace(/\//g, "-");
      const encodedName = encodeURIComponent(modifiedName);
      dispatch(getProductByIdHandler(storeById?.StoreId, product?._id));
      navigate(`/${id}/productdetail/${encodedName}/${product?._id}`);
  };
  
  useEffect(() => {
    if (authToken) {
      dispatch(LoggedInHandler(authToken)).then((result) => {
        if (result.status === "success") {
          const data = {
            User_Numberid: result.data.mobile,
          };
          dispatch(getCartHandler(verfiyToken, authToken, data.User_Numberid));
          dispatch(GetAddressHandler(authToken, result.data.id));

          if (authToken && cartData?.length > 0) {
            dispatch(
              AddLocalCartHandler(
                verfiyToken,
                authToken,
                cartData,
                data?.User_Numberid
              )
            ).then((result) => {
              if (result.status === "success") {
                dispatch(cartActions.setLocalCartList());

                dispatch(
                  getCartHandler(verfiyToken, authToken, data.User_Numberid)
                );
              }
            });
          }
        }
      });
    }
    dispatch(getStoreByIdHandle(id));
  }, [dispatch, verfiyToken, authToken]);

  const calculateTotalPrice = (cartData) => {
    const total = cartData?.reduce((acc, item) => {
      return acc + item.Product_Price * item.Product_Quantity;
    }, 0);
    return parseFloat(total.toFixed(2))
  };

  const goToAddress = () => {
    navigate("/verification");
    dispatch(authActions.setFromPage("cart"));
  };

  const placeOrder = () => {
    if (authToken) {
      if (AddressKeeper?.length > 0) {
        dispatch(getStoreApprovalHandler(verfiyToken,storeById?.StoreId)).then((result) => {
          if (result.data.CustomerApproval === true) {
            if (window.innerWidth > 767) {
              
              setOrder(true);
              dispatch(orderActions.setCurrentStepHandler(2));
            
              
            } else {
              navigate(`/${id}/orderSteps`);
            }
          } else {
            dispatch(
              getCustomerApprovalHandler(
                verfiyToken,
                storeById?.LocationId,
                defaultAddress?.mobileNumber
              )
            ).then((result) => {
              if (result.status === "success") {
                if (result.data === false) {
                 showError(true);
                  setMsg(
                    "You are not authorised yet. kindly wait for the approval!"
                  );
                } else {
                  if (window.innerWidth > 767) {
                    setOrder(true);
                  } else {
                    navigate(`/${id}/orderSteps`);
                  }
                }
              } else {
                const data = {
                  customerName: defaultAddress.name,
                  Mobile: defaultAddress?.mobileNumber,
                  LocationId: storeById?.LocationId,
                  StoreId: storeById?.StoreId,
                  addressLine1: defaultAddress?.addressLine1,
                  addressLine2: defaultAddress?.addressLine2,
                  city: defaultAddress?.city,
                  landmark: defaultAddress?.landmark,
                  pincode: defaultAddress?.pincode,
                  state: defaultAddress?.state,
                  products: cartList,
                };
                dispatch(AddApprovalHandler(verfiyToken, data)).then(
                  (result) => {
                    if (result.status === "success") {
                       showError(true);
                      setMsg(
                        "Request sent successfully. Kindly wait for the approval!"
                      );
                    } else {
                
                      showError(true);
                      setMsg(
                        "You have already requested your approval request"
                      );
                    }
                  }
                );
              }
            });
          }
        });
      } else {
        navigate(`/${id}/Address`);
      }
    } else {
      navigate("/verification");
      dispatch(authActions.setFromPage("cart"));
    }
  };

  useEffect(() => {
    if (authToken && cartList?.length > 0) {
      const newTotalPrice = calculateTotalPrice(cartList);
      dispatch(cartActions.calculateTotalPriceHandler(newTotalPrice));
    }
  }, [cartList, authToken, dispatch]);

  useEffect(() => {
    if (cartData.length > 0) {
      dispatch(cartActions.calculateTotalLocalPriceHandler(cartData));
    }
  }, []);

  const filterCartContentNotInProductList = (cartContent) => {
    if (cartContent?.length > 0) {
      return cartContent?.filter(
        (item) =>
          !productList.some((product) => product._id === item.Product_Id)
      );
    }
  };
  const handleHeaderClick = () => {
    if (location.key === 'default') {
      navigate(`/${id}/home`);
    } else {
      navigate(-1);
    }
  };


  const cartContentNotInProductList =
    filterCartContentNotInProductList(cartContent);

  useEffect(() => {
    const deleteProductsNotInProductList = () => {
      if (!processed && cartContentNotInProductList?.length > 0) {
        const processedProducts = new Set();
        cartContentNotInProductList?.forEach((product) => {
          if (!processedProducts.has(product.Product_Id)) {
            deleteProdHandle(product);
            processedProducts.add(product.Product_Id);
          }
        });
        setProcessed(true);
      }
    };

    deleteProductsNotInProductList();
  }, [cartContentNotInProductList, deleteProdHandle, processed]);




  return (
    <>
      <div className={`container-fluid ${classes.cartPage}`}>
        <div className={`${classes.CartNavigationHeader}`}>
          <MainHeader />
        </div>
        {error && (
          <PopUp
            title={"Customer Approval"}
            content={msg}
            onClose={() => showError(!error)}
            specialClass="specialHeight"
          />
        )}
        <div className={`${classes.cartWrapper}`}>
          <div className={`${classes.Heading}`}>
            <Header
              heading={"My Cart"}
              onClick= {handleHeaderClick}
              headerAdditional={
                authToken ? (
                  <div
                    className={`${classes.header}   justify-content-between container-fluid `}
                  >
                    {AddressKeeper?.filter(
                      (address) => address.default === true
                    )?.map((address, index) => {
                      return (
                        <div className="d-flex flex-column" key={index}>
                          <div className={`${classes.addressContent}`}>
                            <GeoLocSvg />
                            <span className="ms-2">Delivering to </span>
                            <span className={`${classes.addressRightContent}`}>
                              {" "}
                              : {address.name.split(" ")[0]}
                            </span>
                          </div>
                          <div className={`${classes.CartAddressContent}`}>
                            {address.addressLine1}, {address.city},{" "}
                            {address.state}{" "}
                          </div>
                        </div>
                      );
                    })}

                    <div className={`${classes.changeBtn}`}>
                      <button onClick={() => navigate(`/${id}/manageaddress`)}>
                        {AddressKeeper?.length > 0 ? "Change" : "Add Address"}
                      </button>
                    </div>
                  </div>
                ) : (
                  <div
                    className={`${classes.header}   justify-content-between container-fluid `}
                  >
                    <div className={`${classes.changeBtn} `}>
                      {/* <div className={`${classes.changeBtn} ms-2  mt-4`}> */}
                      <button onClick={goToAddress}>Add Address</button>
                    </div>
                  </div>
                )
              }
            />
          </div>

          <div className={` ${classes.cartAllData}`}>
            <div className={` ${classes.productAdjust}`}>
              {cartContent?.length > 0 ? (
                cartContent?.map((product, index) => {
                  const correspondingProduct = productList.find(
                    (item) => item.PKID === product.FKProdID
                  );




                  return (
                    <div className={`${classes.productMain} `} key={index}>
                      <div className="d-block text-center  ">
                        <div className={`${classes.productList} d-flex  `}>
                          <div
                            className={`${classes.categoryImage}  position-relative `}
                            onClick={() => ProductDataHandler(product)}
                          >
                            {correspondingProduct?.Images?.length > 0 &&
                              correspondingProduct?.Images ? (
                              <img
                                variant="top"
                                src={correspondingProduct?.Images[0]}
                                alt="imageNotAvailable"
                                className="img-fluid"
                              />
                            ) : (
                              <img
                                variant="top"
                                src={noImg}
                                alt="imageNotAvailable"
                                className="img-fluid"
                              />
                            )}
                          </div>
                          <div
                            className={`${classes.categoryName} ${classes.productName}  pt-1`}
                          >
                            <h6
                              className={` ${classes.cartProductText} text-capitalize`}
                              onClick={() => ProductDataHandler(product)}
                            >
                              {product?.Product_name}
                            </h6>
                            {/* <p className={`${classes.productInfo} text-capitalize mb-0`} >{product.Product_detail}</p> */}
                            <p className={`${classes.Product_Quantity}`}>
                              Qty: {product.Product_Quantity}{" "}
                            </p>

                            <p className={`${classes.amount}`}>
                              ₹ {product.Product_Price}{" "}
                              {/* <span className={`${classes.previousAmount} `}>
                            <del>₹{product.Product_Price * 2} </del>
                          </span>{" "} */}
                            </p>

                            <button className={`${classes.item}`}>
                              <AddToCart product={product} />
                            </button>


                            <div className={`${classes.deleteIcon}`}

                              data-bs-toggle="modal"

                              data-bs-target="#exampleModal">
                              <i
                                className="bi bi-trash3"
                                // onClick={() => deleteProdHandle(product)}
                                onClick={() => setProduct(product)}
                              ></i>
                            </div>
                            <div
                              className="modal fade"
                              id="exampleModal"
                              tabIndex="-1"
                              aria-labelledby="exampleModalLabel"
                              aria-hidden="true"
                            >
                              <div
                                className={`${classes.addressConfirmPopup} modal-dialog modal-dialog-centered`}

                              >
                                <div className="modal-content" style={{ borderRadius: "0px", marginLeft: "150px" }}>
                                  <div className="modal-header">
                                    <h1 className="modal-title fs-5" id="exampleModalLabel">
                                      Move from Bag
                                    </h1>
                                    <button
                                      type="button"
                                      className="btn-close"
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                  <div
                                    className={`modal-body`}>
                                    Are you want to move this item from Bag?
                                  </div>
                                  <div className="modal-footer">
                                    <button
                                      type="button"
                                      className="btn btn-primary ConModal"
                                      onClick={() => addToWishlist(productDel, "remove")}

                                      data-bs-dismiss="modal"
                                    >

                                      {loading ? (
                                        <div className={`${classes.setLoaderBtn}`}>

                                          <Loader />

                                        </div>
                                      ) : " Add to Wishilist "}
                                    </button>





                                    <button
                                      type="button"
                                      className="btn btn-secondary CanModal"
                                      onClick={() => deleteProdHandle(productDel)}

                                      data-bs-dismiss="modal"
                                    >Delete

                                      {/* {loader ? (
                    <div className={`${classes.setLoaderBtn}`}>
                
                      <Loader/>
                    
                    </div>
                  ) :"Delete"} */}
                                    </button>
                                  </div>

                                </div>

                              </div>
                            </div>




                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className={`${classes.noDataContainer}`}>
                  <img
                    // src={gifImage}
                    src={EmptyCart}
                    alt="No data GIF"
                    className={`${classes.gifImage}`}
                  />
                </div>
              )}
            </div>

            {cartContent?.length > 0 && (
              <div className={`${classes.footer}  d-flex align-items-start`}>
                {order === true ? (
                  <OrderSteps />
                ) : (
                  <>
                    <div className={`${classes.CartText} d-flex mt-1`}>
                      <div className={`${classes.CartPay}`}>
                        Payable Amount{" "}
                      </div>
                      <p className={`${classes.amount} `}>
                        ₹ {authToken ? totalPrice : cartPrice}
                      </p>
                    </div>

                    <button
                      type="button"
                      className={` ${classes.applyBtn} btn btn-primary`}
                      onClick={placeOrder}
                    >
                      Place Order{" "}
                    </button>

                    <div className={`${classes.OfferImage}`}>
                      <div>
                        <CartImage1 />
                      </div>
                      <div>
                        {" "}
                        <CartImage2 />
                      </div>
                      <div>
                        {" "}
                        <CartImage3 />
                      </div>
                    </div>
                  </>
                )}
              </div>
            )}
          </div>

          <div className={`${classes.YouMayLikeProducts}`}>
            <h3 className={`${classes.YouMayLikeProductsHeading}`}>
              You may also like
            </h3>

            <div className={`${classes.ProductFormats}`}>
              {productList?.slice(1, 7).map((product, index) => {
                const findProd = authToken
                  ? cartList?.length > 0 &&
                  cartList?.find(
                    (cartprod) => cartprod.Product_Id === product._id
                  )
                  : cartData?.find(
                    (cartprod) => cartprod.Product_Id === product._id
                  );

                const findFav = wishlist?.product?.find(
                  (wish) => wish === product?.PKID
                );
                return (
                  <div className="d-block text-center" key={index}>
                    <div className={`${classes.recommendProduct} `}>
                      {/* <div className={`${classes.favourite} `}>
                        {findFav ? (
                          <div onClick={() => removeWishlist(product?.PKID)}>
                            <FavouriteSvg />
                          </div>
                        ) : (
                          <div onClick={() => addToWishlist(product?.PKID)}>
                            <NotFavouriteSvg />
                          </div>
                        )}
                      </div> */}
                      {product?.appliedPromotions?.length > 0 &&
                        product?.appliedPromotionOnProductCategoryVise
                          ?.length === 0 &&
                        product?.appliedPromotionOnProductBrandVise?.length ===
                        0 &&
                        withinPromotionTime(
                          product?.appliedPromotions?.[
                            product?.appliedPromotions.length - 1
                          ]?.ProductPromotionFromTime,
                          product?.appliedPromotions?.[
                            product?.appliedPromotions.length - 1
                          ]?.ProductPromotionToTime
                        ) &&
                        (!product?.appliedPromotions[
                          product?.appliedPromotions.length - 1
                        ]?.FKLocationID ||
                          product?.appliedPromotions[
                            product?.appliedPromotions.length - 1
                          ]?.FKLocationID === storeById?.LocationId) && (
                          <div className={`${classes.promotion}`}>
                            <div className={`${classes.promotionPrice}`}>
                              {
                                product?.appliedPromotions[
                                  product?.appliedPromotions.length - 1
                                ]?.ProductPromotionPercentageRate
                              }
                              % off
                            </div>
                          </div>
                        )}

                      {product?.appliedPromotions?.length > 0 &&
                        (product?.appliedPromotionOnProductCategoryVise
                          ?.length > 0 ||
                          product?.appliedPromotionOnProductBrandVise?.length >
                          0) &&
                        withinPromotionTime(
                          product?.appliedPromotions?.[
                            product?.appliedPromotions.length - 1
                          ]?.ProductPromotionFromTime,
                          product?.appliedPromotions?.[
                            product?.appliedPromotions.length - 1
                          ]?.ProductPromotionToTime
                        ) &&
                        (!product?.appliedPromotions[
                          product?.appliedPromotions.length - 1
                        ]?.FKLocationID ||
                          product?.appliedPromotions[
                            product?.appliedPromotions.length - 1
                          ]?.FKLocationID === storeById?.LocationId) && (
                          <div className={`${classes.promotion}`}>
                            <div className={`${classes.promotionPrice}`}>
                              {
                                product?.appliedPromotions[
                                  product?.appliedPromotions.length - 1
                                ]?.ProductPromotionPercentageRate
                              }
                              % off
                            </div>
                          </div>
                        )}

                      {product?.appliedPromotions?.length === 0 &&
                        product?.appliedPromotionOnProductCategoryVise?.length >
                        0 &&
                        product?.appliedPromotionOnProductBrandVise?.length >
                        0 &&
                        withinPromotionTime(
                          product?.appliedPromotionOnProductCategoryVise[
                            product?.appliedPromotionOnProductCategoryVise
                              .length - 1
                          ]?.ProductCategoryPromotionFromTime,
                          product?.appliedPromotionOnProductCategoryVise[
                            product?.appliedPromotionOnProductCategoryVise
                              .length - 1
                          ]?.ProductCategoryPromotionToTime
                        ) &&
                        (!product?.appliedPromotionOnProductCategoryVise[
                          product?.appliedPromotionOnProductCategoryVise
                            .length - 1
                        ]?.FKLocationID ||
                          product?.appliedPromotionOnProductCategoryVise[
                            product?.appliedPromotionOnProductCategoryVise
                              .length - 1
                          ]?.FKLocationID === storeById?.LocationId) && (
                          <div className={`${classes.promotion}`}>
                            <div className={`${classes.promotionPrice}`}>
                              {
                                product?.appliedPromotionOnProductCategoryVise[
                                  product?.appliedPromotionOnProductCategoryVise
                                    .length - 1
                                ]?.ProductPromotionCategoryPercentageRate
                              }
                              % off
                            </div>
                          </div>
                        )}

                      {product?.appliedPromotionOnProductCategoryVise?.length >
                        0 &&
                        product?.appliedPromotions?.length === 0 &&
                        product?.appliedPromotionOnProductBrandVise?.length ===
                        0 &&
                        withinPromotionTime(
                          product?.appliedPromotionOnProductCategoryVise[
                            product?.appliedPromotionOnProductCategoryVise
                              .length - 1
                          ]?.ProductCategoryPromotionFromTime,
                          product?.appliedPromotionOnProductCategoryVise[
                            product?.appliedPromotionOnProductCategoryVise
                              .length - 1
                          ]?.ProductCategoryPromotionToTime
                        ) &&
                        (!product?.appliedPromotionOnProductCategoryVise[
                          product?.appliedPromotionOnProductCategoryVise
                            .length - 1
                        ]?.FKLocationID ||
                          product?.appliedPromotionOnProductCategoryVise[
                            product?.appliedPromotionOnProductCategoryVise
                              .length - 1
                          ]?.FKLocationID === storeById?.LocationId) && (
                          <div className={`${classes.promotion}`}>
                            <div className={`${classes.promotionPrice}`}>
                              {
                                product?.appliedPromotionOnProductCategoryVise[
                                  product?.appliedPromotionOnProductCategoryVise
                                    .length - 1
                                ]?.ProductPromotionCategoryPercentageRate
                              }
                              % off
                            </div>
                          </div>
                        )}

                      {product?.appliedPromotionOnProductBrandVise?.length >
                        0 &&
                        product?.appliedPromotionOnProductCategoryVise
                          ?.length === 0 &&
                        product?.appliedPromotions?.length === 0 &&
                        withinPromotionTime(
                          product?.appliedPromotionOnProductBrandVise[
                            product?.appliedPromotionOnProductBrandVise.length -
                            1
                          ]?.ProductBrandPromotionFromTime,
                          product?.appliedPromotionOnProductBrandVise[
                            product?.appliedPromotionOnProductBrandVise.length -
                            1
                          ]?.ProductBrandPromotionToTime
                        ) &&
                        (!product?.appliedPromotionOnProductBrandVise[
                          product?.appliedPromotionOnProductBrandVise.length - 1
                        ]?.FKLocationID ||
                          product?.appliedPromotionOnProductBrandVise[
                            product?.appliedPromotionOnProductBrandVise.length -
                            1
                          ]?.FKLocationID === storeById?.LocationId) && (
                          <div className={`${classes.promotion}`}>
                            <div className={`${classes.promotionPrice}`}>
                              {
                                product?.appliedPromotionOnProductBrandVise[
                                  product?.appliedPromotionOnProductBrandVise
                                    .length - 1
                                ]?.ProductPromotionBrandPercentageRate
                              }
                              % off
                            </div>
                          </div>
                        )}
                      <div
                        className={`${classes.RecommendProduct}  position-relative `}
                        onClick={() => ProductDataLikeHandler(product)}
                      >
                        {product?.Images?.length > 0 && product?.Images ? (
                          <img
                            variant="top"
                            src={product?.Images[0]}
                            alt="imageNotAvailable"
                            className="img-fluid"
                          />
                        ) : (
                          <img
                            variant="top"
                            src={noImg}
                            alt="imageNotAvailable"
                            className="img-fluid"
                          />
                        )}
                      </div>

                      <div className={`${classes.DetailName} pt-2`}>
                        <h6
                          className={`${classes.Brandname}  text-capitalize `}
                        >
                       
                          {product?.ERPBrand}{" "}
                        </h6>

                        <h6
                          className={`${classes.ProductName}  text-capitalize `}
                          onClick={() => ProductDataLikeHandler(product)}
                        >
                          {product?.NameToDisplay}{" "}
                        </h6>
                        <div className={`${classes.rating} pb-1  `}>
                          {Array.from({ length: 5 }, (_, index) => {
                            return index <
                              Math.round(product?.AverageProductRating) ? (
                              <i
                                key={index}
                                className="bi bi-star-fill pe-1"
                              ></i>
                            ) : (
                              <i key={index} className="bi bi-star pe-1"></i>
                            );
                          })}
                        </div>
                        <div className="d-flex gap-2">
                          <p className={`${classes.RecommendAmount} mb-0`}>
                            ₹{product?.SaleRate}
                          </p>
                        </div>
                        <div>
                          {findProd ? (
                            <button className={`${classes.itemSet}`}>
                              <AddToCart product={findProd} />
                            </button>
                          ) : (
                            <button
                              className={`${classes.cartBtnDetail}`}
                              onClick={() => addCart(product)}
                            >
                              <CartSvg /> ADD
                            </button>


                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>



      {/* {
        confirmDelete && 
        <PopUp
        title={"Move from Bag"}
        order={true}
        deletebutton={true}
        wishlistbutton={true}
        
       // wishlist = {deleteProdHandle()}
        
        content={"Are you want to move this item from Bag?"}

        onClose={()=>(setConfirmDelete(!confirmDelete))}
         />
      } */}
      <MainFooter />

    </>
  );
};

export default Cart;
