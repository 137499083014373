import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import classes from "./Home.module.css";
import { cartActions } from "../../Store/Cart/CartSlice";
import { useNavigate } from "react-router-dom";
import {
  PostWishlistHandler,
  RemoveWishlistHandler,
  getProductByIdHandler,
  getProductHandler,
  getWishlistHandler,
} from "../../Store/Product/ProductActions";
import AddToCart from "../../UI/AddToCart/AddToCart";
import { AddCartHandler, getCartHandler } from "../../Store/Cart/CartActions";
import { LoggedInHandler } from "../../Store/Auth/AuthActions";
import promotionTag from "../../Assets/Images/promotionTag.svg";
import noImg from "../../Assets/Images/noImage.png";
import { ReactComponent as NotFavouriteSvg } from "../../Assets/SvgComponents/NotFavouriteSvg.svg";
import { ReactComponent as FavouriteSvg } from "../../Assets/SvgComponents/FavouriteSvg.svg";
import { ReactComponent as CartSvg } from "../../Assets/SvgComponents/CartSvg.svg";
import { productActions } from "../../Store/Product/ProductSlice";
import Loader from "../../UI/Loader/Loader";
import gifImage from "../../Assets/Images/noWishlist.gif";
import { authActions } from "../../Store/Auth/AuthSlice";
import NoProductsImage from "../../Assets/Images/NoProductsImage.png";

const Product = ({ querySet, category }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const productReduxList = useSelector((state) => state.product.productList);
  const cartList = useSelector((state) => state.cart.cartList);
  const verfiyToken = useSelector((state) => state.auth.verfiyToken);
  const wishlist = useSelector((state) => state.product.wishlist);
  const wish = useSelector((state) => state.product.wish);
  const Profile = useSelector((state) => state.auth.Profile);
  const query = useSelector((state) => state.product.query);
  const storeById = useSelector((state) => state.store.storeById);
  const unmatchedCategory = useSelector(
    (state) => state.product.unmatchedCategory
  );
  const storeId = useSelector((state) => state.store.storeId);

  const authToken = localStorage.getItem("authToken");
  const IsAllProduct = localStorage.getItem("IsAllProduct");
  const cartData = useSelector((state) => state.cart.cartData);

  const [productList, setProductList] = useState(productReduxList);

  const [visibleRows, setVisibleRows] = useState();
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState("");

  const withinPromotionTime = (fromTime, toTime) => {
    const currentTime = new Date();
    const currentHours = currentTime.getHours();
    const currentMinutes = currentTime.getMinutes();

    const fromTimeParts = fromTime && fromTime.split(":");
    const toTimeParts = toTime && toTime.split(":");

    const fromHours = fromTimeParts && parseInt(fromTimeParts[0], 10);
    const fromMinutes = fromTimeParts && parseInt(fromTimeParts[1], 10);

    const toHours = toTimeParts && parseInt(toTimeParts[0], 10);
    const toMinutes = toTimeParts && parseInt(toTimeParts[1], 10);

    if (
      toHours > fromHours ||
      (toHours === fromHours && toMinutes >= fromMinutes)
    ) {
      return (
        (currentHours > fromHours ||
          (currentHours === fromHours && currentMinutes >= fromMinutes)) &&
        (currentHours < toHours ||
          (currentHours === toHours && currentMinutes <= toMinutes))
      );
    } else {
      return (
        currentHours > fromHours ||
        (currentHours === fromHours && currentMinutes >= fromMinutes) ||
        currentHours < toHours ||
        (currentHours === toHours && currentMinutes <= toMinutes)
      );
    }
  };

  const addToCart = (product) => {
    const data = {
      Product_Id: product._id,
      FKProdID: product.PKID,
      // FKLotID: 10000001,
      Product_name: product.NameToDisplay,
      Product_Quantity: 1,
      Product_detail: product.Description,
      Product_Price: product.SaleRate,

      User_Numberid: Profile?.MobileNumber,
    };

    if (authToken) {
      dispatch(
        AddCartHandler(verfiyToken, authToken, data, data.User_Numberid)
      ).then((result) => {
        if (result.status === "success") {
          dispatch(getCartHandler(verfiyToken, authToken, data.User_Numberid));
        }
      });
    } else {
      dispatch(
        cartActions.pushProToCartListHandler({
          Product_Id: product._id,
          FKProdID: product.PKID,
          // FKLotID: 10000001,
          Product_name: product.NameToDisplay,
          Product_Quantity: 1,
          Product_detail: product.Description,
          Product_Price: product.SaleRate,

          User_Numberid: Profile?.MobileNumber,

          // product: product,
        })
      );
      dispatch(cartActions.calculateTotalLocalPriceHandler(product));
    }
  };

  const ProductDataHandler = (product) => {
    const modifiedName = product.NameToDisplay.replace(/ /g, "-")
      .replace(/'/g, "")
      .replace(/:/g, "-")
      .replace(/\//g, "-");
    const encodedName = encodeURIComponent(modifiedName);
    dispatch(getProductByIdHandler(storeById?.StoreId, product._id));
    // dispatch(getVariantIdHandler(verfiyToken, product?.PKID ))
    navigate(`/${storeId}/productdetail/${encodedName}/${product._id}`);
  };

  const addToWishlist = (WishlistProductId) => {
    const data = {
      WishlistProductId: WishlistProductId,
      User_Number: Profile?.MobileNumber,
      appKeyToken: verfiyToken,
    };
    if (authToken) {
      dispatch(LoggedInHandler(authToken)).then((result) => {
        if (result.status === "success") {
          dispatch(PostWishlistHandler(authToken, data)).then((result) => {
            if (result.status === "success") {
              dispatch(
                getWishlistHandler(
                  authToken,
                  { appKeyToken: verfiyToken },
                  Profile?.MobileNumber
                )
              );
            } else {
              navigate("/verification");
              dispatch(authActions.setFromPage("home"));
            }
          });
        }
      });
    } else {
      navigate("/verification");
      dispatch(authActions.setFromPage("home"));
    }
  };

  const removeWishlist = (id) => {
    dispatch(
      RemoveWishlistHandler(authToken, Profile?.MobileNumber, id, {
        appKeyToken: verfiyToken,
      })
    ).then((result) => {
      if (result.status === "success") {
        dispatch(
          getWishlistHandler(
            authToken,
            { appKeyToken: verfiyToken },
            Profile?.MobileNumber
          )
        );
      } else {
        navigate("/verification");
        dispatch(authActions.setFromPage("home"));
      }
    });
  };

  useEffect(() => {
    dispatch(productActions.setWishHandler(false));
    if (authToken) {
      dispatch(LoggedInHandler(authToken)).then((result) => {
        if (result.status === "success") {
          const data = {
            User_Numberid: result.data.mobile,
          };
          dispatch(getCartHandler(verfiyToken, authToken, data.User_Numberid));
          dispatch(
            getWishlistHandler(
              authToken,
              { appKeyToken: verfiyToken },
              result.data.mobile
            )
          );
        }
      });
    }
  }, [dispatch, verfiyToken, authToken]);

  useEffect(() => {
    setLoading(true);
    let tempQuery = structuredClone(query);
    tempQuery.IsAllProduct = IsAllProduct;
    dispatch(productActions.setProductListHandler([]));
    dispatch(getProductHandler(storeById?.StoreId, tempQuery)).then((result) => {
      if (result.status === "success") {
        dispatch(productActions.setProductListHandler(result.data));
        setProductList(result.data);
        setLoading(false);
        return;
      }
      if (result.status === "error") {
        setLoading(false);
        setProductList([]);
        // setMsg("Products are not available for this store at this time.");
      }
    });
  }, [dispatch, query, verfiyToken]);

  // const sortedProductList = [...productList].sort((a, b) => {
  //   const aHasPromotion =
  //     a.appliedPromotions?.length > 0 ||
  //     a.appliedPromotionOnProductCategoryVise?.length > 0;
  //   const bHasPromotion =
  //     b.appliedPromotions?.length > 0 ||
  //     b.appliedPromotionOnProductCategoryVise?.length > 0;

  //   if (aHasPromotion && bHasPromotion) {
  //     const aPromotionRate =
  //       a.appliedPromotions[a?.appliedPromotions?.length-1]?.ProductPromotionPercentageRate ||
  //       a.appliedPromotionOnProductCategoryVise[   a?.appliedPromotionOnProductCategoryVise
  //         ?.length - 1]
  //         ?.ProductPromotionCategoryPercentageRate ||
  //       0;
  //     const bPromotionRate =
  //       b.appliedPromotions[b?.appliedPromotions?.length-1]?.ProductPromotionPercentageRate ||
  //       b.appliedPromotionOnProductCategoryVise[   b?.appliedPromotionOnProductCategoryVise
  //         ?.length - 1]
  //         ?.ProductPromotionCategoryPercentageRate ||
  //       0;
  //     return bPromotionRate - aPromotionRate;
  //   }
  //   return bHasPromotion - aHasPromotion;
  // });

  const sortedProductList = [...productList].sort((a, b) => {
    const aHasPromotion =
      a.appliedPromotions?.length > 0 ||
      a.appliedPromotionOnProductCategoryVise?.length > 0 ||
      a.appliedPromotionOnProductBrandVise?.length > 0;

    const bHasPromotion =
      b.appliedPromotions?.length > 0 ||
      b.appliedPromotionOnProductCategoryVise?.length > 0 ||
      b.appliedPromotionOnProductBrandVise?.length > 0;

    if (aHasPromotion && bHasPromotion) {
      const aPromotionRate =
        a.appliedPromotions[a?.appliedPromotions?.length - 1]
          ?.ProductPromotionPercentageRate ||
        a.appliedPromotionOnProductCategoryVise[
          a?.appliedPromotionOnProductCategoryVise?.length - 1
        ]?.ProductPromotionCategoryPercentageRate ||
        a.appliedPromotionOnProductBrandVise[
          a?.appliedPromotionOnProductBrandVise?.length - 1
        ]?.ProductPromotionBrandPercentageRate ||
        0;

      const bPromotionRate =
        b.appliedPromotions[b?.appliedPromotions?.length - 1]
          ?.ProductPromotionPercentageRate ||
        b.appliedPromotionOnProductCategoryVise[
          b?.appliedPromotionOnProductCategoryVise?.length - 1
        ]?.ProductPromotionCategoryPercentageRate ||
        b.appliedPromotionOnProductBrandVise[
          b?.appliedPromotionOnProductBrandVise?.length - 1
        ]?.ProductPromotionBrandPercentageRate ||
        0;

      return bPromotionRate - aPromotionRate;
    }

    return bHasPromotion - aHasPromotion;
  });

  const wishCheck = sortedProductList?.filter((product) =>
    wishlist?.WishlistProductId?.some(
      (wishlistItem) => wishlistItem === product.PKID
    )
  );

  const filteredProductList = category
    ? sortedProductList.filter((product) =>
        product?.Category?.some((catId) => catId === category)
      )
    : sortedProductList;

  // useEffect(() => {
  //   if (category && filteredProductList) {
  //     const unmatchedCategories = [category].filter(cat => !filteredProductList?.some(product => product?.Category?.some(catId => catId === cat)));

  //     if (unmatchedCategories.length > 0 ) {

  //       dispatch(productActions.setUnMatchedCat( [...unmatchedCategory, ...unmatchedCategories]));
  //     } else {
  //       dispatch(productActions.setUnMatchedCat([]));
  //     }
  //   } else {
  //     dispatch(productActions.setUnMatchedCat([]));
  //   }
  // }, []);
  // console.log(wishlist)

  return (
    // <div className={"row"}>
    <>
      {loading === false ? (
        <div className={`${classes.productMainRow}`}>
          <>
            {wish === true && authToken ? (
              wishCheck.length > 0 ? (
                sortedProductList
                  ?.filter((product) =>
                    wishlist?.WishlistProductId?.some(
                      (wishlistItem) => wishlistItem === product.PKID
                    )
                  )
                  ?.slice(0, querySet ? visibleRows : productList?.length)
                  ?.map((product, id) => {
                    const findProd =
                      cartList?.length > 0 &&
                      cartList?.find(
                        (cartprod) => cartprod.Product_Id === product._id
                      );

                    const findFav = wishlist?.WishlistProductId?.find(
                      (wish) => wish === product.PKID
                    );

                    return (
                      <div
                        className={`${classes.productMain}`}
                        key={id}
                        id={id}
                      >
                        <div className="d-block text-center  ">
                          <div className={`${classes.productList} `}>
                            <div className={`${classes.favourite}  `}>
                              {findFav ? (
                                <div
                                  onClick={() => removeWishlist(product.PKID)}
                                >
                                  <FavouriteSvg />
                                </div>
                              ) : (
                                <div
                                  onClick={() => addToWishlist(product.PKID)}
                                >
                                  <NotFavouriteSvg />
                                </div>
                              )}
                            </div>
                            {product?.appliedPromotions?.length > 0 &&
                              product?.appliedPromotionOnProductCategoryVise
                                ?.length === 0 &&
                              product?.appliedPromotionOnProductBrandVise
                                ?.length === 0 &&
                              withinPromotionTime(
                                product?.appliedPromotions?.[
                                  product?.appliedPromotions.length - 1
                                ]?.ProductPromotionFromTime,
                                product?.appliedPromotions?.[
                                  product?.appliedPromotions.length - 1
                                ]?.ProductPromotionToTime
                              ) &&
                              (!product?.appliedPromotions[
                                product?.appliedPromotions.length - 1
                              ]?.FKLocationID ||
                                product?.appliedPromotions[
                                  product?.appliedPromotions.length - 1
                                ]?.FKLocationID === storeById?.LocationId) && (
                                <div className={`${classes.promotion}`}>
                                  <div className={`${classes.promotionPrice}`}>
                                    {
                                      product?.appliedPromotions[
                                        product?.appliedPromotions.length - 1
                                      ]?.ProductPromotionPercentageRate
                                    }
                                    % off
                                  </div>
                                </div>
                              )}

                            {product?.appliedPromotions?.length > 0 &&
                              (product?.appliedPromotionOnProductCategoryVise
                                ?.length > 0 ||
                                product?.appliedPromotionOnProductBrandVise
                                  ?.length > 0) &&
                              withinPromotionTime(
                                product?.appliedPromotions?.[
                                  product?.appliedPromotions.length - 1
                                ]?.ProductPromotionFromTime,
                                product?.appliedPromotions?.[
                                  product?.appliedPromotions.length - 1
                                ]?.ProductPromotionToTime
                              ) &&
                              (!product?.appliedPromotions[
                                product?.appliedPromotions.length - 1
                              ]?.FKLocationID ||
                                product?.appliedPromotions[
                                  product?.appliedPromotions.length - 1
                                ]?.FKLocationID === storeById?.LocationId) && (
                                <div className={`${classes.promotion}`}>
                                  <div className={`${classes.promotionPrice}`}>
                                    {
                                      product?.appliedPromotions[
                                        product?.appliedPromotions.length - 1
                                      ]?.ProductPromotionPercentageRate
                                    }
                                    % off
                                  </div>
                                </div>
                              )}

                            {product?.appliedPromotions?.length === 0 &&
                              product?.appliedPromotionOnProductCategoryVise
                                ?.length > 0 &&
                              product?.appliedPromotionOnProductBrandVise
                                ?.length > 0 &&
                              withinPromotionTime(
                                product?.appliedPromotionOnProductCategoryVise[
                                  product?.appliedPromotionOnProductCategoryVise
                                    .length - 1
                                ]?.ProductCategoryPromotionFromTime,
                                product?.appliedPromotionOnProductCategoryVise[
                                  product?.appliedPromotionOnProductCategoryVise
                                    .length - 1
                                ]?.ProductCategoryPromotionToTime
                              ) &&
                              (!product?.appliedPromotionOnProductCategoryVise[
                                product?.appliedPromotionOnProductCategoryVise
                                  .length - 1
                              ]?.FKLocationID ||
                                product?.appliedPromotionOnProductCategoryVise[
                                  product?.appliedPromotionOnProductCategoryVise
                                    .length - 1
                                ]?.FKLocationID === storeById?.LocationId) && (
                                <div className={`${classes.promotion}`}>
                                  <div className={`${classes.promotionPrice}`}>
                                    {
                                      product
                                        ?.appliedPromotionOnProductCategoryVise[
                                        product
                                          ?.appliedPromotionOnProductCategoryVise
                                          .length - 1
                                      ]?.ProductPromotionCategoryPercentageRate
                                    }
                                    % off
                                  </div>
                                </div>
                              )}

                            {product?.appliedPromotionOnProductCategoryVise
                              ?.length > 0 &&
                              product?.appliedPromotions?.length === 0 &&
                              product?.appliedPromotionOnProductBrandVise
                                ?.length === 0 &&
                              withinPromotionTime(
                                product?.appliedPromotionOnProductCategoryVise[
                                  product?.appliedPromotionOnProductCategoryVise
                                    .length - 1
                                ]?.ProductCategoryPromotionFromTime,
                                product?.appliedPromotionOnProductCategoryVise[
                                  product?.appliedPromotionOnProductCategoryVise
                                    .length - 1
                                ]?.ProductCategoryPromotionToTime
                              ) &&
                              (!product?.appliedPromotionOnProductCategoryVise[
                                product?.appliedPromotionOnProductCategoryVise
                                  .length - 1
                              ]?.FKLocationID ||
                                product?.appliedPromotionOnProductCategoryVise[
                                  product?.appliedPromotionOnProductCategoryVise
                                    .length - 1
                                ]?.FKLocationID === storeById?.LocationId) && (
                                <div className={`${classes.promotion}`}>
                                  <div className={`${classes.promotionPrice}`}>
                                    {
                                      product
                                        ?.appliedPromotionOnProductCategoryVise[
                                        product
                                          ?.appliedPromotionOnProductCategoryVise
                                          .length - 1
                                      ]?.ProductPromotionCategoryPercentageRate
                                    }
                                    % off
                                  </div>
                                </div>
                              )}

                            {product?.appliedPromotionOnProductBrandVise
                              ?.length > 0 &&
                              product?.appliedPromotionOnProductCategoryVise
                                ?.length === 0 &&
                              product?.appliedPromotions?.length === 0 &&
                              withinPromotionTime(
                                product?.appliedPromotionOnProductBrandVise[
                                  product?.appliedPromotionOnProductBrandVise
                                    .length - 1
                                ]?.ProductBrandPromotionFromTime,
                                product?.appliedPromotionOnProductBrandVise[
                                  product?.appliedPromotionOnProductBrandVise
                                    .length - 1
                                ]?.ProductBrandPromotionToTime
                              ) &&
                              (!product?.appliedPromotionOnProductBrandVise[
                                product?.appliedPromotionOnProductBrandVise
                                  .length - 1
                              ]?.FKLocationID ||
                                product?.appliedPromotionOnProductBrandVise[
                                  product?.appliedPromotionOnProductBrandVise
                                    .length - 1
                                ]?.FKLocationID === storeById?.LocationId) && (
                                <div className={`${classes.promotion}`}>
                                  <div className={`${classes.promotionPrice}`}>
                                    {
                                      product
                                        ?.appliedPromotionOnProductBrandVise[
                                        product
                                          ?.appliedPromotionOnProductBrandVise
                                          .length - 1
                                      ]?.ProductPromotionBrandPercentageRate
                                    }
                                    % off
                                  </div>
                                </div>
                              )}
                            <div
                              className={`${classes.categoryImage}  position-relative `}
                              onClick={() => ProductDataHandler(product)}
                            >
                              {product?.Images?.length > 0 &&
                              product?.Images ? (
                                <img
                                  variant="top"
                                  src={product.Images[0]}
                                  alt="imageNotAvailable"
                                  className="img-fluid"
                                />
                              ) : (
                                <img
                                  variant="top"
                                  src={noImg}
                                  alt="imageNotAvailable"
                                  className="img-fluid"
                                />
                              )}
                            </div>
                            <div
                              className={`${classes.categoryName} ${classes.productName} `}
                            >
                              <h6
                                className={`${classes.productBrand}  text-capitalize `}
                                onClick={() => ProductDataHandler(product)}
                              >
                                {product?.ERPBrand}{" "}
                              </h6>

                              <h6
                                className={`${classes.productTitle}  text-capitalize `}
                                onClick={() => ProductDataHandler(product)}
                              >
                                {product?.Product}{" "}
                              </h6>

                            
                              <div className={`${classes.rating} pb-1  `}>
                              {Array.from({ length: 5 }, (_, index) => {
                                return index <
                                  Math.round(product?.AverageProductRating) ? (
                                  <i
                                    key={index}
                                    className="bi bi-star-fill pe-1"
                                  ></i>
                                ) : (
                                  <i
                                    key={index}
                                    className="bi bi-star pe-1"
                                  ></i>
                                );
                              })}
                            </div>
                            <p className={`${classes.amount} mb-0`}>
                                ₹{product.SaleRate}
                              </p>
                              <div>
                                {findProd ? (
                                  <button className={`${classes.item}`}>
                                    <AddToCart product={findProd} />
                                  </button>
                                ) : (
                                  <button
                                    className={`${classes.cartBtn}`}
                                    onClick={() => addToCart(product)}
                                  >
                                    <CartSvg /> ADD
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
              ) : (
                <div className={`${classes.noDataContainer}`}>
                  <img
                    src={gifImage}
                    alt="No data GIF"
                    className={`${classes.gifImage}`}
                  />
                </div>
              )
            ) : filteredProductList.length > 0 ? (
              filteredProductList
                ?.slice(0, querySet ? visibleRows : productList?.length)
                ?.map((product, id) => {
                  const findProd = authToken
                    ? cartList?.length > 0 &&
                      cartList?.find(
                        (cartprod) => cartprod.Product_Id === product._id
                      )
                    : cartData?.find(
                        (cartprod) => cartprod.Product_Id === product._id
                      );

                  const findFav = wishlist?.WishlistProductId?.find(
                    (wish) => wish === product.PKID
                  );

                  return (
                    <div
                      className={`${classes.productMain}`}
                      key={id}
                      id={id}
                    >
                      <div className="d-block text-center   ">
                        <div className={`${classes.productList} `}>
                          <div className={`${classes.favourite} `}>
                            {findFav ? (
                              <div onClick={() => removeWishlist(product.PKID)}>
                                <FavouriteSvg />
                              </div>
                            ) : (
                              <div onClick={() => addToWishlist(product.PKID)}>
                                <NotFavouriteSvg />
                              </div>
                            )}
                          </div>
                          {product?.appliedPromotions?.length > 0 &&
                            product?.appliedPromotionOnProductCategoryVise
                              ?.length === 0 &&
                            product?.appliedPromotionOnProductBrandVise
                              ?.length === 0 &&
                            withinPromotionTime(
                              product?.appliedPromotions?.[
                                product?.appliedPromotions.length - 1
                              ]?.ProductPromotionFromTime,
                              product?.appliedPromotions?.[
                                product?.appliedPromotions.length - 1
                              ]?.ProductPromotionToTime
                            ) &&
                            (!product?.appliedPromotions[
                              product?.appliedPromotions.length - 1
                            ]?.FKLocationID ||
                              product?.appliedPromotions[
                                product?.appliedPromotions.length - 1
                              ]?.FKLocationID === storeById?.LocationId) && (
                              <div className={`${classes.promotion}`}>
                                <div className={`${classes.promotionPrice}`}>
                                  {
                                    product?.appliedPromotions[
                                      product?.appliedPromotions.length - 1
                                    ]?.ProductPromotionPercentageRate
                                  }
                                  % off
                                </div>
                              </div>
                            )}

                          {product?.appliedPromotions?.length > 0 &&
                            (product?.appliedPromotionOnProductCategoryVise
                              ?.length > 0 ||
                              product?.appliedPromotionOnProductBrandVise
                                ?.length > 0) &&
                            withinPromotionTime(
                              product?.appliedPromotions?.[
                                product?.appliedPromotions.length - 1
                              ]?.ProductPromotionFromTime,
                              product?.appliedPromotions?.[
                                product?.appliedPromotions.length - 1
                              ]?.ProductPromotionToTime
                            ) &&
                            (!product?.appliedPromotions[
                              product?.appliedPromotions.length - 1
                            ]?.FKLocationID ||
                              product?.appliedPromotions[
                                product?.appliedPromotions.length - 1
                              ]?.FKLocationID === storeById?.LocationId) && (
                              <div className={`${classes.promotion}`}>
                                <div className={`${classes.promotionPrice}`}>
                                  {
                                    product?.appliedPromotions[
                                      product?.appliedPromotions.length - 1
                                    ]?.ProductPromotionPercentageRate
                                  }
                                  % off
                                </div>
                              </div>
                            )}

                          {product?.appliedPromotions?.length === 0 &&
                            product?.appliedPromotionOnProductCategoryVise
                              ?.length > 0 &&
                            product?.appliedPromotionOnProductBrandVise
                              ?.length > 0 &&
                            withinPromotionTime(
                              product?.appliedPromotionOnProductCategoryVise[
                                product?.appliedPromotionOnProductCategoryVise
                                  .length - 1
                              ]?.ProductCategoryPromotionFromTime,
                              product?.appliedPromotionOnProductCategoryVise[
                                product?.appliedPromotionOnProductCategoryVise
                                  .length - 1
                              ]?.ProductCategoryPromotionToTime
                            ) &&
                            (!product?.appliedPromotionOnProductCategoryVise[
                              product?.appliedPromotionOnProductCategoryVise
                                .length - 1
                            ]?.FKLocationID ||
                              product?.appliedPromotionOnProductCategoryVise[
                                product?.appliedPromotionOnProductCategoryVise
                                  .length - 1
                              ]?.FKLocationID === storeById?.LocationId) && (
                              <div className={`${classes.promotion}`}>
                                <div className={`${classes.promotionPrice}`}>
                                  {
                                    product
                                      ?.appliedPromotionOnProductCategoryVise[
                                      product
                                        ?.appliedPromotionOnProductCategoryVise
                                        .length - 1
                                    ]?.ProductPromotionCategoryPercentageRate
                                  }
                                  % off
                                </div>
                              </div>
                            )}

                          {product?.appliedPromotionOnProductCategoryVise
                            ?.length > 0 &&
                            product?.appliedPromotions?.length === 0 &&
                            product?.appliedPromotionOnProductBrandVise
                              ?.length === 0 &&
                            withinPromotionTime(
                              product?.appliedPromotionOnProductCategoryVise[
                                product?.appliedPromotionOnProductCategoryVise
                                  .length - 1
                              ]?.ProductCategoryPromotionFromTime,
                              product?.appliedPromotionOnProductCategoryVise[
                                product?.appliedPromotionOnProductCategoryVise
                                  .length - 1
                              ]?.ProductCategoryPromotionToTime
                            ) &&
                            (!product?.appliedPromotionOnProductCategoryVise[
                              product?.appliedPromotionOnProductCategoryVise
                                .length - 1
                            ]?.FKLocationID ||
                              product?.appliedPromotionOnProductCategoryVise[
                                product?.appliedPromotionOnProductCategoryVise
                                  .length - 1
                              ]?.FKLocationID === storeById?.LocationId) && (
                              <div className={`${classes.promotion}`}>
                                <div className={`${classes.promotionPrice}`}>
                                  {
                                    product
                                      ?.appliedPromotionOnProductCategoryVise[
                                      product
                                        ?.appliedPromotionOnProductCategoryVise
                                        .length - 1
                                    ]?.ProductPromotionCategoryPercentageRate
                                  }
                                  % off
                                </div>
                              </div>
                            )}

                          {product?.appliedPromotionOnProductBrandVise?.length >
                            0 &&
                            product?.appliedPromotionOnProductCategoryVise
                              ?.length === 0 &&
                            product?.appliedPromotions?.length === 0 &&
                            withinPromotionTime(
                              product?.appliedPromotionOnProductBrandVise[
                                product?.appliedPromotionOnProductBrandVise
                                  .length - 1
                              ]?.ProductBrandPromotionFromTime,
                              product?.appliedPromotionOnProductBrandVise[
                                product?.appliedPromotionOnProductBrandVise
                                  .length - 1
                              ]?.ProductBrandPromotionToTime
                            ) &&
                            (!product?.appliedPromotionOnProductBrandVise[
                              product?.appliedPromotionOnProductBrandVise
                                .length - 1
                            ]?.FKLocationID ||
                              product?.appliedPromotionOnProductBrandVise[
                                product?.appliedPromotionOnProductBrandVise
                                  .length - 1
                              ]?.FKLocationID === storeById?.LocationId) && (
                              <div className={`${classes.promotion}`}>
                                <div className={`${classes.promotionPrice}`}>
                                  {
                                    product?.appliedPromotionOnProductBrandVise[
                                      product
                                        ?.appliedPromotionOnProductBrandVise
                                        .length - 1
                                    ]?.ProductPromotionBrandPercentageRate
                                  }
                                  % off
                                </div>
                              </div>
                            )}
                          <div
                            className={`${classes.categoryImage}  position-relative `}
                            onClick={() => ProductDataHandler(product)}
                          >
                            {product?.Images?.length > 0 ? (
                              <img
                                variant="top"
                                src={product.Images[0]}
                                alt="imageNotAvailable"
                                className="img-fluid"
                              />
                            ) : (
                              <img
                                variant="top"
                                src={noImg}
                                alt="imageNotAvailable"
                                className="img-fluid"
                              />
                            )}
                          </div>
                          <div
                            className={`${classes.categoryName} ${classes.productName}  pt-2`}
                          >
                            <h6
                              className={`${classes.productBrand}  text-capitalize `}
                              onClick={() => ProductDataHandler(product)}
                            >
                              {/* {product.NameToDisplay}{" "} */}
                              {product?.ERPBrand}{" "}
                            </h6>

                            <h6
                              className={`${classes.productTitle}  text-capitalize `}
                              onClick={() => ProductDataHandler(product)}
                            >
                              {/* {product.NameToDisplay}{" "} */}
                              {product?.Product}{" "}
                            </h6>

                        
                            {/* <p>
                                <span className={`${classes.previousAmount} `}>
                                  <del>₹{product.MRP * 2}</del>
                                </span>
                              </p> */}

                            <div className={`${classes.rating} pb-1  `}>
                              {Array.from({ length: 5 }, (_, index) => {
                                return index <
                                  Math.round(product?.AverageProductRating) ? (
                                  <i
                                    key={index}
                                    className="bi bi-star-fill pe-1"
                                  ></i>
                                ) : (
                                  <i
                                    key={index}
                                    className="bi bi-star pe-1"
                                  ></i>
                                );
                              })}
                            </div>
                            <p className={`${classes.amount} `}>
                              ₹{product.SaleRate}
                            </p>
                            <div>
                              {findProd ? (
                                <button className={`${classes.item}`}>
                                  <AddToCart product={findProd} />
                                </button>
                              ) : (
                                <button
                                  className={`${classes.cartBtn}`}
                                  onClick={() => addToCart(product)}
                                >
                                  <CartSvg /> ADD
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
            ) : (
              <div className={`${classes.NoProductsImageFound} `}>
                <img src={NoProductsImage} alt="No data GIF" />
              </div>
            )}
          </>
        </div>
      ) : (
        <div className={`${classes.LoaderSet}`}>
          <Loader />
          {/* <PageLoader /> */}
        </div>
      )}
      {msg && <h4 className="text-danger">{msg}</h4>}
    </>
  );
};

export default Product;
