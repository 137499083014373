import React, { useEffect, useRef, useState } from "react";
import classes from "./Home.module.css";
import "../../UI/global.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  findStoreHandler,
  getCurrentStoreLocHandler,
  getStoreByIdHandle,
  getStoreLocHandler,
} from "../../Store/StoreDetail/StoreActions";
import { authActions } from "../../Store/Auth/AuthSlice";
import noData2 from "../../Assets/Images/noData2.jpg";
import no_store_found from "../../Assets/Images/no_store_found.png";
import NoStoreLogo from "../../Assets/Images/NoStoreLogo.svg";
import Swilmart from "../../Assets/Images/swilmart.png";
import { ReactComponent as SearchSvg } from "../../Assets/SvgComponents/SearchSvg.svg";
import { productActions } from "../../Store/Product/ProductSlice";
import { storeActions } from "../../Store/StoreDetail/StoreSlice";

const Home = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const verfiyToken = useSelector((state) => state.auth.verfiyToken);
  const stores = useSelector((state) => state.store.stores);
  const [searchText, setSearchText] = useState("");
  const [findText, setFindText] = useState("");
  const cities = [
    {
      name: "Jaipur",
      location: { latitude: 26.9124, longitude: 75.7873 },
    },
    {
      name: "Jodhpur",
      location: { latitude: 26.2389, longitude: 73.0243 },
    },
    {
      name: "Udaipur",
      location: { latitude: 24.5854, longitude: 73.7125 },
    },
    {
      name: "Kota",
      location: { latitude: 25.2138, longitude: 75.8648 },
    },
    {
      name: "Bikaner",
      location: { latitude: 28.0229, longitude: 73.3119 },
    },
    {
      name: "Ajmer",
      location: { latitude: 26.4499, longitude: 74.6399 },
    },
    {
      name: "Jaisalmer",
      location: { latitude: 26.9157, longitude: 70.9082 },
    },
    {
      name: "Alwar",
      location: { latitude: 27.5643, longitude: 76.625 },
    },
    {
      name: "Bharatpur",
      location: { latitude: 27.2174, longitude: 77.4904 },
    },
    {
      name: "Mount Abu",
      location: { latitude: 24.5925, longitude: 72.7156 },
    },
    {
      name: "Chittorgarh",
      location: { latitude: 24.8791, longitude: 74.6258 },
    },
    {
      name: "Sikar",
      location: { latitude: 27.6116, longitude: 75.1399 },
    },
    {
      name: "Pali",
      location: { latitude: 25.7721, longitude: 73.3234 },
    },
    {
      name: "Hanumangarh",
      location: { latitude: 29.61, longitude: 74.2895 },
    },
    {
      name: "Bhilwara",
      location: { latitude: 25.3471, longitude: 74.6362 },
    },
  ];
  const [currentLocation, setCurrentLocation] = useState(null);
  const [filteredCities, setFilteredCities] = useState(cities);

  const getLocation = () => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        const { latitude, longitude } = position.coords;
        setCurrentLocation({ latitude, longitude });
        dispatch(
          getCurrentStoreLocHandler(verfiyToken, { latitude, longitude })
        );
      });
    } else {
      alert("Geolocation is not available in your browser.");
    }
  };

  const handleSearch = (e) => {
    const inputText = e.target.value;
    setSearchText(inputText);

    const filtered = cities?.filter((city) =>
      city.name.toLowerCase().includes(inputText.toLowerCase())
    );

    setFilteredCities(filtered);
  };

  
  const debouncingTimeout = useRef(null);

  const handleFind = (e) => {
    const value = e.target.value;
    setFindText(value);
  };

  useEffect(() => {
    if (debouncingTimeout.current) {
      clearTimeout(debouncingTimeout.current);
    }

    debouncingTimeout.current = setTimeout(() => {
      if (findText) {
        dispatch(findStoreHandler(verfiyToken, { address: findText }));
      } else {
        dispatch(getStoreLocHandler(verfiyToken));
      }
    }, 300);

    return () => {
      clearTimeout(debouncingTimeout.current);
    };
  }, [findText, verfiyToken, dispatch]);

  const getByCityLocation = (location) => {
    dispatch(getCurrentStoreLocHandler(verfiyToken, location));
  };

  const enterStore = (store) => {
    dispatch(authActions.setTokenHandler(store?.appKey));
    dispatch(getStoreByIdHandle(store?._id)).then((result) => {
      if (result.status === "success") {
        dispatch(storeActions.setStoreId(store?._id));
        navigate(`/${store?._id}/home`);
      }
    });
  };

  const getClassName = (businessDetails) => {
    if (businessDetails === "Pharma") {
      return classes.PharmaStore;
    } else if (businessDetails === "Garments") {
      return classes.FashionStore;
    } else {
      return classes.regularCard;
    }
  };  

  useEffect(() => {
    dispatch(getStoreLocHandler(verfiyToken));
    dispatch(productActions.setWishHandler(false));
  }, [dispatch, verfiyToken]);



  return (
    <div className={`${classes.storeSelectWrapper} container-fluid`}>
      <div className="row">
        <div className="col-12 col-sm-12 col-xs-12">
          <div className={`${classes.wrapHead} fixed-top`}>
            <div className={`${classes.HomeImg}`} onClick={() => navigate("/")}>
              <img src={Swilmart} className="img-fluid" alt="frame" />
            </div>
            <div className={`${classes.searchContainer} `}>
              <div className={`${classes.inputLocGeo}`}>
                <span className={`${classes.searchSet}`}>
                  <SearchSvg />
                </span>
                <input
                  type="search"
                  className={`${classes.inputSearch} form-control `}
                  id="exampleInputEmail1"
                  aria-label="Search"
                  placeholder="Find Stores"
                  onChange={handleFind}
                  value={findText}
                />
                <button
                  onClick={getLocation}
                  className={`${classes.locCurrent}`}
                >
                  <i className="bi bi-geo-alt-fill"></i>
                </button>
              </div>
              <div
                className={`${classes.filterContainer}`}
                data-bs-toggle="modal"
                data-bs-target="#staticBackdrop"
              >
                <i
                  className="bi bi-sliders2"
                  data-bs-toggle="modal"
                  data-bs-target="#staticBackdrop"
                ></i>
              </div>
            </div>
          </div>
          <div className={`${classes.cards}`}>
            {stores?.length > 0 ? (
              stores?.map((store, index) => (
                <div
                  className={` ${getClassName(store.BusinessDetail)} ${
                    classes.card
                  }`}
                  onClick={() => enterStore(store)}
                  key={index}
                >
                  <div className={`${classes.CardDetails}`}>
                    <div className={`${classes.HomeImg1}`}>
                      {store?.Logo ? (
                        <img src={store?.Logo} />
                      ) : (
                        <img src={NoStoreLogo} />
                      )}
                    </div>
                    <div className={`${classes.cardData}`}>
                      <h1>{store?.StoreName}</h1>
                      <p>{store?.LocationName}</p>
                      <div className={`${classes.cardContent}`}>
                        <button onClick={() => enterStore(store)}>
                          Enter Store
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* <div className={`${classes.CardImage}`}>
                    <img src={storeCardImage} alt="img" />
                  </div> */}
                </div>
              ))
            ) : (
              // <h6 className="text-danger align-text-center"> No stores found! </h6>
              <img
                src={no_store_found}
                className={`${classes.noStoreImage} `}
                alt="no stores found"
              />
            )}
          </div>
        </div>
      </div>
      <div
        className={`${classes.cityModal} modal  fade `}
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div
          className={`modal-dialog  modal-dialog-scrollable  ${classes.searchDropdownStore} `}
        >
          <div className={`modal-content ${classes.paymentModalContent} `}>
            <div className={`modal-header ${classes.paymentModalHeader} py-2 `}>
              <h6>Select City</h6>
              <div className={`${classes.FilterHeader} `}>
                <button
                  type="button"
                  className="btn-close "
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
            </div>
            <div className={`modal-body ${classes.paymentModalBody}`}>
              <div className={`${classes.searchContainer}`}>
                <span className={`${classes.searchSet}`}>
                  <SearchSvg />
                </span>
                <input
                  type="search"
                  className={`${classes.inputSearch} ${classes.locSearch} form-control`}
                  id="exampleInputEmail1"
                  aria-label="Search"
                  placeholder="Search City"
                  onChange={handleSearch}
                  value={searchText}
                />
              </div>
              <div className={`${classes.cityList}`}>
                {filteredCities.length === 0 ? (
                  <div className={`${classes.noDataStorePage}`}>
                    <img src={noData2} alt="No Data" />
                  </div>
                ) : (
                  filteredCities?.map((city, index) => (
                    <div
                      key={index}
                      onClick={() => getByCityLocation(city.location)}
                    >
                      <span>{city.name}, Raj</span>
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
