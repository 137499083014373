import { json } from "react-router-dom";
import {
  CustomerInstance,
  NodeInstance,
  StoreInstance,
} from "../../Router/AxiosInstance";
import { authActions } from "./AuthSlice";
import { addressActions } from "../Address/AddressSlice";
import { cartActions } from "../Cart/CartSlice";
import { productActions } from "../Product/ProductSlice";
import { storeActions } from "../StoreDetail/StoreSlice";

// export const clientRegIDVerify = (data1) => {
//   return async (dispatch) => {
//     let result = {
//       status: "",
//       msg: "",
//       data: [],
//     };

//     let data = JSON.stringify(data1);

//     let config = {
//       method: "post",
//       maxBodyLength: Infinity,
//       url: "/api/auth/ClientRegIDVerify",
//       headers: {
//         "Content-Type": "application/json",
//       },
//       data: data,
//     };

//     await StoreInstance.request(config)
//       .then((response) => {
//         // console.log(JSON.stringify(response.data));
//         result = response.data;
//       })
//       .catch((error) => {
//         console.log(error);
//       });
//     return result;
//   };
// };

export const sendOtpHandle = (verfiyToken, data1) => {
  return async (dispatch) => {
    let result = {
      status: "",
      msg: "",
      data: [],
    };

    let data = JSON.stringify(data1);

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "/customer/send-otp",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + verfiyToken,
      },
      data: data,
    };

    await CustomerInstance(config)
      .then(function (response) {
        if (response.data.status === "success") {
          result.status = "success";
          // console.log(JSON.stringify(response.data.data));
        }
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  };
};

export const resendOtpHandle = (verfiyToken, data1) => {
  return async (dispatch) => {
    let result = {
      status: "",
      msg: "",
      data: [],
    };

    let data = JSON.stringify(data1);

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "/customer/resend-otp",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + verfiyToken,
      },
      data: data,
    };

    await CustomerInstance(config)
      .then(function (response) {
        if (response.data.status === "success") {
          result.status = "success";
          // console.log(JSON.stringify(response.data.data));
        }
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  };
};

export const verifyOtpHandle = (verfiyToken, data1) => {
  return async (dispatch) => {
    let result = {
      status: "",
      msg: "",
      data: [],
    };

    let data = JSON.stringify(data1);

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "/customer/verify-otp",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + verfiyToken,
      },
      data: data,
    };

    await CustomerInstance(config)
      .then(function (response) {
        if (response.data.status === "success") {
          result.status = "success";
          result.data = response.data;
        }
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  };
};

export const LogOutHandle = (verfiyToken) => {
  return async (dispatch) => {
    let result = {
      status: "",
      msg: "",
      data: [],
    };

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "/customer/logout",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + verfiyToken,
      },
    };

    await CustomerInstance(config)
      .then(function (response) {
        if (response.data.status === "success") {
          result.status = "success";
          // console.log(JSON.stringify(response.data.data));
          // localStorage.removeItem("authToken");
          // dispatch(authActions.setProfileFormatter());
          // dispatch(addressActions.setAddressHandler([]));
          // dispatch(addressActions.setAddressFormatter());
          // dispatch(cartActions.setCartList([]));
          // dispatch(productActions.setWishlistHandler([]));
          // dispatch(cartActions.setLocalCartList([]));
          // dispatch(storeActions.setStoreHandler())

          localStorage.clear();
          dispatch({type : "reset"})
        }
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  };
};

export const createProfileHandle = (verfiyToken, data) => {
  return async (dispatch) => {
    let result = {
      status: "",
      msg: "",
      data: [],
    };

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "/customer/create-customer-profile",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + verfiyToken,
      },
      data: data,
    };

    await CustomerInstance(config)
      .then(function (response) {
        if (response.data.status === "success") {
          result.status = "success";
          result.data = response.data.data;
          // console.log(JSON.stringify(response.data.data));
        }
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  };
};

export const updateProfileHandle = (verfiyToken, data, id) => {
  return async (dispatch) => {
    let result = {
      status: "",
      msg: "",
      data: [],
    };

    let config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `/customer/update-customer-profile/${id}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + verfiyToken,
      },
      data: data,
    };

    await CustomerInstance(config)
      .then(function (response) {
        if (response.data.status === "success") {
          result.status = "success";
          result.data = response.data.data;
          // console.log(JSON.stringify(response.data.data));
        }
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  };
};

export const getProfileHandle = (verfiyToken, num) => {
  return async (dispatch) => {
    let result = {
      status: "",
      msg: "",
      data: [],
    };

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `/customer/findProfileByMobile/${num}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + verfiyToken,
      },
    };

    await CustomerInstance(config)
      .then(function (response) {
        if (response.data.status === "success") {
          result.status = "success";
          // console.log(JSON.stringify(response.data.data));
          dispatch(authActions.setProfileHandler(response.data.user));
          result.data = response.data.user
        }
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  };
};

export const LoggedInHandler = (Token) => {
  return async (dispatch) => {
    let result = {
      status: "",
      msg: "",
      data: [],
    };

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `/customer/loggedin`,

      headers: {
        Authorization: "Bearer " + Token,
      },
    };

    await CustomerInstance(config)
      .then((response) => {
        if (response.data.status === "success") {
          result.status = "success";
          result.data.mobile = response.data.user.MobileNumber;
          result.data.id = response.data.user._id;

          dispatch(
            authActions.setProfileFieldHandler({
              name: "Mobile",
              value: response.data.user.MobileNumber,
            })
          );
        }
      })
      .catch(function (error) {
        dispatch(LogOutHandle(Token));
        localStorage.removeItem("authToken");
        console.log(error);
      });
    return result;
  };
};

export const getCustomerByMobile = (Token, num) => {
  return async (dispatch) => {
    let result = {
      status: "",
      msg: "",
      data: [],
    };

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `/admin/getcustomerByMobie/${num}`,

      headers: {
        Authorization: "Bearer " + Token,
      },
    };

    await CustomerInstance(config)
      .then(function (response) {
      
        if (response.data.status === "success") {
          result.status = "success";
          result.data = response.data.user._id;
          dispatch(authActions.setCustomerHandler(response.data.user));
        }
      })
      .catch(function (error) {
        console.log(error);
        dispatch(authActions.setCustomerHandler());

      });
    return result;
  };
};
