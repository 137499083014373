import { createSlice } from "@reduxjs/toolkit";

const cartSlice = createSlice({
  name: "cart",
  initialState: {
    cartList: [],
    totalPrice: 0,
    cartData: [],
    cartPrice: 0,
  },
  reducers: {
    calculateTotalPriceHandler(state, action) {
      state.totalPrice = action.payload;
    },

    setCartList(state, action) {
      state.cartList = action.payload;
      state.cartData = action.payload;
    },

    pushProToCartListHandler(state, action) {
      
        var product = action.payload;
        state.cartData = [...state.cartData, product];
    },

    productIncrementHandler(state, action) {
      const pro = action.payload;

      let tempcartData = state.cartData;
      tempcartData.forEach((cartProd) => {
        if (cartProd.Product_Id === pro.Product_Id) {
          let tempItems = cartProd.Product_Quantity + 1;

          cartProd.Product_Quantity = tempItems;
        }
      });
      state.cartData = tempcartData;
    },

    productDecrementHandler(state, action) {
      const pro = action.payload;
      let tempcartData = state.cartData;
      tempcartData.forEach((cartProd) => {
        if (cartProd.Product_Id === pro.Product_Id) {
          cartProd.Product_Quantity = cartProd.Product_Quantity - 1;
        }
      });

      state.cartData = tempcartData;
    },

    removeProductHandler(state, action) {
      const product = action.payload;
      let tempcartData = state.cartData?.filter(
        (cartProd) => cartProd.Product_Id !== product.Product_Id
      );
      state.cartData = tempcartData;
      
    },

    calculateTotalLocalPriceHandler(state) {
      const totalPrice = state.cartData?.reduce((total, cartProd) => {
        return (
          total + cartProd.Product_Quantity * cartProd.Product_Price
        );
      }, 0);

      state.cartPrice = totalPrice;
    },
    setLocalCartList(state, action) {
      state.cartData = [];
    },
  },
});

export const cartActions = cartSlice.actions;

export default cartSlice;
