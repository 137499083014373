
import React, { useEffect } from "react";
import Carousel from "react-bootstrap/Carousel";
import classes from "./Home.module.css";
import fashionStoreSideImage from "../../Assets/Images/fashionStoreSideImage.png";
import pharmacyStoreSideImage from "../../Assets/Images/pharmacyStoreSideImage.png";
import banner1 from "../../Assets/Images/banner1.png";
import Banner from "../../Assets/Images/Banner.png";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getInvoicePromoHandler } from "../../Store/Product/ProductActions";
import { productActions } from "../../Store/Product/ProductSlice";
import NoStoreLogo from "../../Assets/Images/NoStoreLogo.svg";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Slider = () => {
  const promoInvoice = useSelector((state) => state.product.promoInvoice);
  const verfiyToken = useSelector((state) => state.auth.verfiyToken);
  const storeById = useSelector((state) => state.store.storeById);
  const storeId = useSelector((state) => state.store.storeId);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getInvoicePromoHandler(storeById?.StoreId)).then((result) => {
      if (result.status === "success") {
        dispatch(
          productActions.setPromoInvoiceHandler(result.data[0]?.invoice)
        );
      }
    });
  }, [dispatch, verfiyToken]);

  const getImageSource = (businessDetails) => {
    if (businessDetails === "Pharma") {
      return pharmacyStoreSideImage;
    } else if (businessDetails === "Garments") {
      return fashionStoreSideImage;
    } else {
      return fashionStoreSideImage;
    }
  };

  const totalImages = promoInvoice?.length > 0 ? 2 : 1;

//   return (
//     <div className={`${classes.PromoWrapper} container-fluid`}>
//       <div className={`${classes.StoreImgBox}`}>
      
//         <LazyLoadImage
//           src={getImageSource(storeById?.BusinessDetail)}
//           alt="store-image"
//           loading="lazy"
//         />

//         <div className={`${classes.StoreBannerText}`}>
//           <p
//             className={`${classes.BackStore}`}
//             onClick={() => navigate("/storeSelect")}
//           >
//             <i className="bi bi-chevron-left"></i>Back to All Stores
//           </p>

//           {storeById?.Logo ? (
//             <LazyLoadImage src={storeById?.Logo} />
//           ) : (
//             <LazyLoadImage src={NoStoreLogo} />
//           )}
//           <h3>{storeById?.StoreName}</h3>
//           <h5>{storeById?.LocationName}</h5>
//           <h5>{storeById?.address}</h5>
//         </div>
//       </div>
//       <div className={`${classes.PromoSlider}`}>
//         <Carousel>
//           <Carousel.Item>
//             <div className={`${classes.PromoBanner}`}>
//               <LazyLoadImage
//                 className={` d-block w-100 img-fluid`}
//                 onClick={() => navigate(`/${storeId}/ProductDemo`)}
//                 src={banner1}
//                 alt="First slide"

//                 loading="lazy"
//               />
//             </div>

//           </Carousel.Item>

//           {promoInvoice?.length > 0 && (
//             <Carousel.Item>
//               <div className={`${classes.PromoBanner}`}>
//                 <LazyLoadImage
//                   className={` d-block w-100 h-100 `}
//                   src={Banner}
//                   alt="Second slide"
//                   loading="lazy"
//                 />
//                 {promoInvoice?.length > 0 && (
//                   <>
//                     <div className={`${classes.BannerText}`}>
//                       <p className={`${classes.Text1}`}>
//                         {
//                           promoInvoice[promoInvoice?.length - 1]
//                             ?.PromotionPercentageRate
//                         }{" "}
//                         % Off
//                       </p>
//                       <p className={`${classes.Text2}`}>
//                         Upto Rs.{" "}
//                         {
//                           promoInvoice[promoInvoice?.length - 1]
//                             ?.PromotionApplyAmt
//                         }
//                       </p>
//                     </div>

//                     {/* <span className={`${classes.Text1}`}>

//                   </span>
//                   <span className={`${classes.Text2}`}>
//                   </span> */}
//                   </>
//                 )}
//               </div>
//             </Carousel.Item>
//           )}
//         </Carousel>
//       </div>
//     </div>
//   );
// };



  return (
    <div className={`${classes.PromoWrapper} container-fluid`}>
      <div className={`${classes.StoreImgBox}`}>
        <LazyLoadImage
          src={getImageSource(storeById?.BusinessDetail)}
          alt="store-image"
          loading="lazy"
        />

        <div className={`${classes.StoreBannerText}`}>
          <p
            className={`${classes.BackStore}`}
            onClick={() => navigate("/storeSelect")}
          >
            <i className="bi bi-chevron-left"></i>Back to All Stores
          </p>

          {storeById?.Logo ? (
            <LazyLoadImage src={storeById?.Logo} />
          ) : (
            <LazyLoadImage src={NoStoreLogo} />
          )}
          <h3>{storeById?.StoreName}</h3>
          <h5>{storeById?.LocationName}</h5>
          <h5>{storeById?.address}</h5>
        </div>
      </div>
      <div className={`${classes.PromoSlider}`}>
        <Carousel controls={totalImages > 1}>
          <Carousel.Item>
            <div className={`${classes.PromoBanner}`}>
              <LazyLoadImage
                className={` d-block w-100 img-fluid`}
                onClick={() => navigate(`/${storeId}/ProductDemo`)}
                src={banner1}
                alt="First slide"
                loading="lazy"
              />
            </div>
          </Carousel.Item>

          {promoInvoice?.length > 0 && (
            <Carousel.Item>
              <div className={`${classes.PromoBanner}`}>
                <LazyLoadImage
                  className={` d-block w-100 h-100 `}
                  src={Banner}
                  alt="Second slide"
                  loading="lazy"
                />
                {promoInvoice?.length > 0 && (
                  <>
                    <div className={`${classes.BannerText}`}>
                      <p className={`${classes.Text1}`}>
                        {
                          promoInvoice[promoInvoice?.length - 1]
                            ?.PromotionPercentageRate
                        }{" "}
                        % Off
                      </p>
                      <p className={`${classes.Text2}`}>
                        Upto Rs.{" "}
                        {
                          promoInvoice[promoInvoice?.length - 1]
                            ?.PromotionApplyAmt
                        }
                      </p>
                    </div>
                  </>
                )}
              </div>
            </Carousel.Item>
          )}
        </Carousel>
      </div>
    </div>
  );
};

export default Slider;
