import React from "react";
import toast from "react-hot-toast";
import classes from "./Mart.module.css";
import { useNavigate } from "react-router-dom";
import swilLogo from "../../Assets/Images/swilLogo.png";
import swilmart from "../../Assets/Images/swilmart.png";

const Mart = () => {
  const navigate = useNavigate();

  return (
    <div className="container-fluid">
      <div className={`${classes.mart} row`}>
        <div className="col-12">
          <div className={`${classes.martMain}`}>
            <div className={`${classes.swilLogo}`}>
              <img src={swilLogo} alt="logo" />
            </div>
            <div className={`${classes.martText}`}>
              <h2>Let’s start Shopping with E-store </h2>
            </div>
            <div className={`d-flex  mb-5 ${classes.martStoreMain}`}>
              <div className={`${classes.storeBox}`}>
                <img src={swilmart} alt="logo" />
                <div className={`${classes.martStore}`}>
                  <h6>Visit the stores </h6>
                  <p>
                    Step into Swilmart, where a universe of amazing awaits!
                    Discover unique products across our various stores.
                  </p>
                  <button onClick={() => navigate("/storeSelect")}>
                    Visit Store
                  </button>
                </div>
              </div>
              <div className={`${classes.storeBox}`}>
                <img src={swilmart} alt="swil" />
                <div className={`${classes.martStore}`}>
                  <h6>About Swilmart</h6>
                  <p>
                    Step into Swilmart, where a universe of amazing awaits!
                    Discover unique products across our various stores.
                  </p>
                  <button
                    onClick={() => toast.error("Page is under construction!")}
                  >
                    Visit Site
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mart;
