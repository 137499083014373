import React, { useState, useEffect, useRef } from "react";
import classes from "./Profile.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import OtpInput from "react-otp-input";
import {
  LoggedInHandler,
  getProfileHandle,
  resendOtpHandle,
  verifyOtpHandle,
} from "../../Store/Auth/AuthActions";
import { toast } from "react-hot-toast";
import { Toaster } from "react-hot-toast";

const VerificationOtp = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const phone = useSelector((state) => state.auth.Phone);
  const verfiyToken = useSelector((state) => state.auth.verfiyToken);
  const fromPage = useSelector((state) => state.auth.fromPage);
  const storeId = useSelector((state) => state.store.storeId);

  const [otp, setOtp] = useState("");
  const [showResendTimer, setShowResendTimer] = useState(false);
  const [resendCountdown, setResendCountdown] = useState(30);
  const [resendCount, setResendCount] = useState(0);
  const [showTryAgain, setShowTryAgain] = useState(false);
  const [invalidOTP, setInvalidOTP] = useState(false);
  const intervalRef = useRef(null);

  const handleOtpChange = (otpValue) => {
    setOtp(otpValue);
  };

  const verifyOtp = () => {
    const data = {
      MobileNumber: phone,
      otp: otp,
    };

    dispatch(verifyOtpHandle(verfiyToken, data)).then((result) => {
      if (result.status === "success") {
        const Token = result.data.token;
        localStorage.setItem("authToken", Token);
        dispatch(LoggedInHandler(Token)).then((result) => {
          if (result.status === "success") {
            const data = {
              User_Numberid: result.data.mobile,
            };
            toast("Logged In Successfully", {
              style: {
                border: "1px solid #006d5b",
                padding: "8px 14px",
                background: "#006d5b",
                color: "#fff",
              },
            });
            dispatch(getProfileHandle(verfiyToken, data.User_Numberid)).then(
              (result) => {
                if (result.data?.Username) {
                  // navigate(-3);
                  navigate(`/${storeId}/home`)
                } else navigate(`/${storeId}/editprofile`);
              }
            );
          }
        });
      } else {
        setInvalidOTP(true);
        toast.error("Invalid OTP", {
          style: {
            border: "1px solid #ff4b4",
            padding: "8px 14px",
            background: "#ff4b4b",
            color: "#fff",
          },
          icon: <i class="bi bi-x-lg"></i>,
        });
      }
    });
  };

  const resendOtp = () => {
    if (resendCount < 2) {
      const data = {
        mobile: phone,
      };
      dispatch(resendOtpHandle(verfiyToken, data));
      startResendTimer();
      setResendCount(resendCount + 1);
    } else {
      setShowTryAgain(true);
      setShowResendTimer(false);
    }
  };

  const isNextButtonDisabled = otp?.length !== 4;

  // const startResendTimer = () => {
  //   setShowResendTimer(true);
  //   setResendCountdown(30);
  //   const interval = setInterval(() => {
  //     setResendCountdown((prevCountdown) => {
  //       if (prevCountdown === 1) {
  //         setShowResendTimer(false);
  //         clearInterval(interval);
  //         return 0;
  //       }
  //       return prevCountdown - 1;
  //     });
  //   }, 1000);
  // };

  const startResendTimer = () => {
    setShowResendTimer(true);
    setResendCountdown(30);
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    
    intervalRef.current = setInterval(() => {
      setResendCountdown((prevCountdown) => {
        if (prevCountdown <= 1) {
          setShowResendTimer(false);
          clearInterval(intervalRef.current); // Clear the interval when countdown finishes
          return 0;
        }
        return prevCountdown - 1;
      });
    }, 1000);  
  };
  
  useEffect(() => {
    startResendTimer();
    return () => {
      // Clear interval on component unmount
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, []);

  useEffect(() => {
    setInvalidOTP(false);
  }, [otp]);

  return (
    <div className="container-fluid">
      <div className={`${classes.VerificationPage}`}>
        <div
          className={`${classes.VerificationPageCross} justify-content-between`}
        >
          <div
            className={`${classes.VerificationPageCrossLeft}`}
            onClick={() => navigate(`/${storeId}/home`)}
          >
            <i className="bi bi-chevron-left"></i>
            <p> Back</p>
          </div>
          <i className="bi bi-x" onClick={() => navigate(`/${storeId}/home`)}></i>
        </div>
        <div className={`${classes.VerificationHead}`}>
          <h2>Phone Number Verification</h2>
        </div>
        <div className={`${classes.VerificationBody}`}>
          <div className={`${classes.VerificationBodyText}`}>
            <div>
              <div>Enter 4 digit code sent to your phone</div>
              <div>
                +91 - {phone.slice(0, 1)}xxxxxxx{phone.slice(-2)}
              </div>
            </div>
          </div>
          <div className={`${classes.VerificationOtpBodyInputBox}`}>
            <div className={`${classes.VerificationOtpBodyInput}`}>
              <OtpInput
                value={otp}
                onChange={handleOtpChange}
                shouldAutoFocus={true}
                numInputs={4}
                renderSeparator={
                  <span className={`${classes.spanText}`}></span>
                }
                renderInput={(props) => (
                  <input
                    {...props}
                    type="text"
                    inputMode="numeric"
                    autoComplete="one-time-code"
                    onKeyPress={(e) => {
                      const pattern = /^[0-9]*$/;
                      if (!pattern.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                  />
                )}
              />
            </div>
            <button
              className={
                isNextButtonDisabled
                  ? `${classes.VerificationBodyInputButton} ${classes.disableBtn}`
                  : `${classes.VerificationBodyInputButton} `
              }
              disabled={isNextButtonDisabled}
              onClick={verifyOtp}
            >
              Next
            </button>
          </div>

          {showTryAgain ? (
            <div className={`${classes.VerificationBodyBottomWarnText}`}>
              You have reached max attempts !
            </div>
          ) : showResendTimer ? (
            <div className={`${classes.VerificationBodyBottomDisableText}`}>
              Resend Code in {resendCountdown} seconds
            </div>
          ) : (
            <div
              className={`${classes.VerificationBodyBottomText}`}
              onClick={resendOtp}
            >
              Resend Code
            </div>
          )}
          {invalidOTP && !isNextButtonDisabled && (
            <div className={`${classes.VerificationBodyBottomWarnText}`}>
              Invalid OTP, please try again.
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default VerificationOtp;
