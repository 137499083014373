import {
  CustomerInstance,
  OrderInstance,
  PaymentInstance,
  StoreInstance,
} from "../../Router/AxiosInstance";
import { orderActions } from "./OrderSlice";

export const getStoreApprovalHandler = (verifyToken,StoreId) => {
  return async (dispatch) => {
    var result = {
      status: "",
      msg: "",
      data: [],
    };

    var config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `/api/customer/getCustomerAutoApproval/${StoreId}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + verifyToken,
      },
    };

    await StoreInstance(config)
      .then(function (response) {
        if (response.data.status === "success") {
          result.status = "success";
          result.data = response.data.data;
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    return result;
  };
};

export const getCustomerApprovalHandler = (verifyToken, StoreId, mobile) => {
  return async (dispatch) => {
    var result = {
      status: "",
      msg: "",
    };

    var config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `/customer/getApprovalByStore&Number?LocationId=${StoreId}&Mobiles=${mobile}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + verifyToken,
      },
    };

    await CustomerInstance(config)
      .then(function (response) {
        if (response.data.status === "success") {
          result.status = "success";
          // console.log(response.data.data)
          result.data = response.data.data.isCustomerApproved;
          dispatch(orderActions.setCustomerOrderApproval(response.data.data));
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    return result;
  };
};

export const AddApprovalHandler = (verfiyToken, data) => {
  return async (dispatch) => {
    let result = {
      status: "",
      msg: "",
    };

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `/customer/addApproval`,

      headers: {
        Authorization: "Bearer " + verfiyToken,
      },
      data: data,
    };

    await CustomerInstance(config)
      .then(function (response) {
        if (response.data.status === "success") {
          result.status = "success";
          // console.log(JSON.stringify(response.data));
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    return result;
  };
};

export const MessageHandler = (authToken, data) => {
  return async (dispatch) => {
    let result = {
      status: "",
      msg: "",
    };

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `api/customer/sendErpSalesORder`,

      headers: {
        Authorization: "Bearer " + authToken,
      },
      data: data,
    };

    await OrderInstance(config)
      .then(function (response) {
        if (response.data.status === "success") {
          result.status = "success";
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    return result;
  };
};

export const createOrderHandler = (authToken, data) => {
  return async (dispatch) => {
    let result = {
      status: "",
      msg: "",
      data: {},
    };

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      // url: `/api/customer/createOrderbyCustomers`,
      url: `/api/customer/createOrder`,


      headers: {
        Authorization: "Bearer " + authToken,
      },
      data: data,
    };

    await OrderInstance(config)
      .then(function (response) {
        if (response.data.status === "success") {
          result.status = "success";
          result.data = response.data;
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    return result;
  };
};

export const updateOrderHandler = (verfiyToken, data, id) => {
  return async (dispatch) => {
    let result = {
      status: "",
      msg: "",
    };

    let config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `/api/customer/updateCustomerOrder/${id}`,
      // /updateOrder/:OrderID

      headers: {
        Authorization: "Bearer " + verfiyToken,
      },
      data: data,
    };

    await OrderInstance(config)
      .then(function (response) {
        if (response.data.status === "success") {
          result.status = "success";

          // console.log(JSON.stringify(response.data));
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    return result;
  };
};

export const getOrderListHandler = (verfiyToken, authToken, id) => {
  return async (dispatch) => {
    var result = {
      status: "",
      msg: "",
      data: [],
    };
    const data = {
      appKeyToken: verfiyToken,
    };
    var config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `/api/customer/getOrderByCustomerID/${id}`,

      headers: {
        Authorization: "Bearer " + authToken,
      },
      data: data,
    };

    await OrderInstance(config)
      .then(function (response) {
        
        console.log("result----------",response.data)
        if (response.data.status === "success") {
          result.status = "success";
          dispatch(orderActions.setOrderListHandler(response.data.user));
          
          // console.log(response.data.user)
        }
      })
      .catch(function (error) {
        console.log(error);
        dispatch(orderActions.setOrderListHandler([]));
      });
    return result;
  };
};

export const getOrderByIdHandler = (verfiyToken, id) => {
  return async (dispatch) => {
    var result = {
      status: "",
      msg: "",
      data: [],
    };

    var config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `/api/customer/getuserbyorderId/${id}`,
      headers: {
        Authorization: "Bearer " + verfiyToken,
      },
    };

    await OrderInstance(config)
      .then(function (response) {
        // console.log("response-------", response)
        if (response.data.status === "success") {
          
          result.status = "success";
          dispatch(orderActions.setOrderByIdHandler(response.data.user));
          result.data = response.data.user;
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    return result;
  };
};


export const createPkidHandler = (verfiyToken, data) => {
  return async (dispatch) => {
    let result = {
      status: "",
      msg: "",
      data: {},
    };

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `/api/customer/createCustomerPKID`,

      headers: {
        Authorization: "Bearer " + verfiyToken,
      },
      data: data,
    };

    await CustomerInstance(config)
      .then(function (response) {
        if (response.status === 200) {
          result.status = "success";
          result.data = response.data.result;
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    return result;
  };
};

export const trackOrderStatus = (authToken, data, id) => {
  return async (dispatch) => {
    var result = {
      status: "",
      msg: "",
    };

    var config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `api/customer/ordertrackingstatus?OrderID=${id}`,
      headers: {
        Authorization: "Bearer " + authToken,
      },
      data: data,
    };

    await OrderInstance(config)
      .then(function (response) {
        if (response.data.status === "success") {
          result.status = "success";
        }
      })
      .catch(function (error) {
        console.log(error);
        result.msg = "Error tracking order status";
        result.status = "error";
      });
    return result;
  };
};

export const OrderCancelHandle = (authToken, data) => {
  return async (dispatch) => {
    var result = {
      status: "",
      msg: "",
    };

    var config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `/api/customer/cancelOrderByCustomer`,
      headers: {
        Authorization: "Bearer " + authToken,
      },
      data: data,
    };

    await OrderInstance(config)
      .then(function (response) {
        if (response.data.status === "success") {
          result.status = "success";
        } else {
          result.status = "failed";
        }
      })
      .catch(function (error) {
        console.log(error);
        result.msg = "Error tracking order status";
        result.status = "error";
      });
    return result;
  };
};

export const ReturnHandle = (authToken, data) => {
  return async (dispatch) => {
    var result = {
      status: "",
      msg: "",
    };

    var config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `/api/customer/ReturnOrderByCustomer`,
      headers: {
        Authorization: "Bearer " + authToken,
      },
      data: data,
    };

    await OrderInstance(config)
      .then(function (response) {
        if (response.data.status === "success") {
          result.status = "success";
        } else {
          result.status = "failed";
        }
      })
      .catch(function (error) {
        console.log(error);
        // result.msg =  "Error tracking order status";
        result.status = "error";
      });
    return result;
  };
};

export const OrderPromoHandle = (verfiyToken, data) => {
  return async (dispatch) => {
    var result = {
      status: "",
      msg: "",
    };

    var config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `/api/customer/productSalesInvoice`,
      headers: {
        Authorization: "Bearer " + verfiyToken,
      },
      data: data,
    };

    await OrderInstance(config)
      .then(function (response) {
        if (response.data.status === "success") {
          result.status = "success";
        } else {
          result.status = "failed";
        }
      })
      .catch(function (error) {
        console.log(error);
        result.msg = "Error tracking order status";
        result.status = "error";
      });
    return result;
  };
};

export const createPaymentOrder = (data) => {
  return async (dispatch) => {
    let result = {
      status: "",
      msg: "",
      data: {},
    };

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `/api/payment/swil/createOrder?provider=razorpayPG`,

      // headers: {
      //   Authorization: "Bearer " + authToken,
      // },
      data: data,
    };

    await PaymentInstance(config)
      .then(function (response) {
        if (response.data.status === "success") {
          result.status = "success";
          result.data = response.data;
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    return result;
  };
};

export const paymentValidation = (data) => {
  return async (dispatch) => {
    let result = {
      status: "",
      msg: "",
      data: {},
    };
    console.log("data-----------", data)

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `/api/payment/swil/ValidatePayment`,


      // headers: {
      //   Authorization: "Bearer " + authToken,
      // },
      data: data,
    };

    await PaymentInstance(config)
      .then(function (response) {
        if (response.data.status === "success") {
          result.status = "success";
          result.data = response.data;
          console.log("RESULT", result.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    return result;
  };
};

export const paymentCapture = (id) => {
  return async (dispatch) => {
    let result = {
      status: "",
      msg: "",
      data: {},
    };

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `/api/payment/swil/PaymentStatus/${id}`,

      // headers: {
      //   Authorization: "Bearer " + authToken,
      // },
      // data: data,
    };

    await PaymentInstance(config)
      .then(function (response) {
        if (response.data.status === "success") {
          result.status = "success";
          result.data = response.data;
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    return result;
  };
};
