import { createSlice } from "@reduxjs/toolkit";
const addressSlice = createSlice({
    name: "address",
    initialState: {
        Address: {},
        AddressKeeper: [],
        AddressFormat:{
            name:"",
            mobileNumber:'',
            addressLine1:"",
            addressLine2:"",
            landmark:"",
            pincode:"",
            city:"",
            state:"",
            type:"",
            default:false,
        },
    },
    reducers: {
        setAddressFieldHandler(state, action) {
            var name = action.payload.name
            var value = action.payload.value
            state.Address[name] = value
        },
        setAddressHandler(state, action) {
         
            state.AddressKeeper = action.payload
        },
        setAddressFormatter(state, action) {
            state.Address = state.AddressFormat
        },
        setAddressEditHandler(state, action) {
            state.Address = action.payload
        },
       
    }
})

export const addressActions = addressSlice.actions

export default addressSlice