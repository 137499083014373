import React, { useEffect } from "react";
import {
  createPaymentOrder,
  paymentValidation,
} from "../../Store/Order/OrderActions";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
// import Razorpay from "../../Assets/Images/Razorpay.png"
import { storeActions } from "../../Store/StoreDetail/StoreSlice";
import Paytm from  "../../Assets/Images/Paytm.png"


const RazorpayButton = ({ totalPrice }) => {
  const dispatch = useDispatch();
  const cartPrice = useSelector((state) => state.cart.cartPrice);
  const authToken = localStorage.getItem("authToken");
  const {id} = useParams();

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(storeActions.setStoreId(id));
    }
  }, [id]);



  const handleClick = async () => {
    dispatch(
      createPaymentOrder({
        globalmerchant_id: "EP_13610",
        // "provider_transaction_id":"24356789"
        erp_transaction_id: "1234567898999",
        provider: "Razorpay_PG",
        amount: totalPrice,
        currency: "INR",
        receipt: "Receipt no. 1",
        notes: {
          notes_key_1: "Tea, Earl Grey, Hot",
          notes_key_2: "Tea, Earl Grey… decaf.",
        },
      })
    ).then((result) => {
      console.log("result", result);
      if (result.status === "success") {

        console.log("RESSUK0000000000000000", result.data.data.provider_creation_response.id)
        if (result.status === "success") {

          console.log("OPtions", result.status);
          const options = {
            key: "rzp_test_bmZjzCLkgS1jqg", // Enter the Key ID generated from the Dashboard
            amount: result.data.amount,
            currency: result.data.currency,
            name: "SWIL",
            description: "Test Transaction",
            image: "https://example.com/your_logo",
            order_id: result.data.data.provider_creation_response.id,
            callback_url: `/${id}/orderSuccessInfo`,
            handler: function (response) {
              console.log("response.razorpay_payment_id--------------------", response)
              dispatch(
                paymentValidation({
                  razorpay_payment_id: response.razorpay_payment_id,
                  order_id: response.razorpay_order_id,
                  razorpay_signature: response.razorpay_signature,
                })
              ).then((validationResult) => {
                if (validationResult.status === "success") {
                  window.location.href = options.callback_url;
                } else {

                  console.error("Payment validation failed:", validationResult.error);
                }
              })

            },


            notes: {
              address: "Razorpay Corporate Office",
            },
            theme: {
              color: "#3399cc",
            },
          };

          const rzp = new window.Razorpay(options);
          rzp.open();
        }
      }
    });
  };

  return (
    <>
    <button id="rzp-button1" style={{ textDecoration: "none", border: "none", padding: "0px" }} onClick={handleClick}>
      {/* <img src={Razorpay} alt="razorpay" /> */}
    </button>
    <button id="rzp-button2" style={{ backgroundColor:"white", textDecoration: "none", border: "none", padding: "0px" }}>
      <img src={Paytm} alt="razorpay" />
    </button>
    </>
  );
};

export default RazorpayButton;
