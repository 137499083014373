import React, { useState, useEffect } from "react";
import "../../UI/global.css";
import classes from "./AllCategory.module.css";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../UI/Header/Header";
import Footer from "../../UI/Footer/Footer";
import { useDispatch, useSelector } from "react-redux";
import { productActions } from "../../Store/Product/ProductSlice";
import {
  PostWishlistHandler,
  RemoveWishlistHandler,
  getProductByIdHandler,
  getProductHandler,
  getWishlistHandler,
} from "../../Store/Product/ProductActions";
import { categoryActions } from "../../Store/Category/CategorySlice";
import { cartActions } from "../../Store/Cart/CartSlice";
import { getCategoryHandler } from "../../Store/Category/CategoryActions";
import AddToCart from "../../UI/AddToCart/AddToCart";
import { AddCartHandler, getCartHandler } from "../../Store/Cart/CartActions";
import { LoggedInHandler } from "../../Store/Auth/AuthActions";
import MainHeader from "../Home/MainHeader";
import MainFooter from "../../UI/Footer/MainFooter";

//img path
import NoProductsImage from "../../Assets/Images/NoProductsImage.png";
import noImg from "../../Assets/Images/noImage.png";
import offerImg from "../../Assets/Images/offer.png";
import { ReactComponent as CartSvg } from "../../Assets/SvgComponents/CartSvg.svg";
import { authActions } from "../../Store/Auth/AuthSlice";
import PageLoader from "../../UI/Loader/PageLoader";
import { storeActions } from "../../Store/StoreDetail/StoreSlice";
import { getStore } from "../../Store/StoreDetail/StoreActions";

const AllCategory = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { categoryId } = useParams();
  const [showInput, setShowInput] = useState(false);
  const [categoryRef, setCategoryRef] = useState(categoryId);

  const [showItem, setShowItem] = useState(false);
  const [loading, setLoading] = useState(false);
  const [toggleState, setToggleState] = useState(true);
  const subCategory = useSelector((state) => state.category.subCategory);
  const verfiyToken = useSelector((state) => state.auth.verfiyToken);
  const authToken = localStorage.getItem("authToken");
  const IsAllProduct = localStorage.getItem("IsAllProduct");
  const productList = useSelector((state) => state.product.productList);
  const query = useSelector((state) => state.product.query);
  const cartList = useSelector((state) => state.cart.cartList);
  const categoryName = useSelector((state) => state.category.categoryName);
  const mainCategoryName = useSelector(
    (state) => state.category.mainCategoryName
  );
  const mainCategory = useSelector((state) => state.category.mainCategory);
  const categoryList = useSelector((state) => state.category.categoryList);
  const wishlist = useSelector((state) => state.product.wishlist);
  const Profile = useSelector((state) => state.auth.Profile);
  const cartData = useSelector((state) => state.cart.cartData);
  const storeById = useSelector((state) => state.store.storeById);
  const [msg, setMsg] = useState("");

  useEffect(() => {
    if (id) {
      dispatch(storeActions.setStoreId(id));
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (id && !verfiyToken) {
      dispatch(getStore(id));
    }
  }, [id, dispatch, verfiyToken]);

  useEffect(() => {
    const handleResize = () => {
      setShowInput(window.innerWidth > 768);
    };
    window.addEventListener("resize", handleResize);
    setShowInput(window.innerWidth > 768);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const BackToHome = (category) => {
    let tempQuery = structuredClone(query);
    tempQuery.categories = "";
    tempQuery.Brand = "";
    dispatch(productActions.setQueryHandler(tempQuery));
    dispatch(categoryActions.setSubCategory(category.children));
    navigate(`/${id}/home`);
  };

  const findProductByCategory = (category) => {
    let tempQuery = structuredClone(query);
    tempQuery.categories = category._id;
    setCategoryRef(category._id);
    navigate(`/${id}/allcategory/${category._id}`);
    tempQuery.IsAllProduct = IsAllProduct;
    dispatch(productActions.setQueryHandler(tempQuery));
    dispatch(categoryActions.setSubCategory(category.children));
    dispatch(categoryActions.setCategoryName(category.name));
  };

  const findProductByMainCategory = (category) => {
    let tempQuery = structuredClone(query);
    tempQuery.categories = category._id;
    tempQuery.IsAllProduct = IsAllProduct;
    dispatch(productActions.setQueryHandler(tempQuery));
    setCategoryRef(category._id);
    navigate(`/${id}/allcategory/${category._id}`);
    dispatch(productActions.setQueryHandler(tempQuery));
    dispatch(categoryActions.setSubCategory(category.children));
    dispatch(categoryActions.setCategoryName(category.name));
    dispatch(categoryActions.setMainCategoryName(category.name));
  };

  useEffect(() => {
    setLoading(true);
    dispatch(productActions.setProductListHandler([]));
    let tempQuery = structuredClone(query);
    tempQuery.categories = categoryRef;
    tempQuery.IsAllProduct = IsAllProduct;
    dispatch(productActions.setQueryHandler(tempQuery));
    dispatch(getProductHandler(storeById?.StoreId, tempQuery)).then(
      (result) => {
        if (result.status === "success") {
          setLoading(false);
          dispatch(productActions.setProductListHandler(result.data));
          return;
        }
        if (result.status === "error") {
          setLoading(false);
        }
      }
    );
  }, [dispatch]);

  const withinPromotionTime = (fromTime, toTime) => {
    const currentTime = new Date();
    const currentHours = currentTime.getHours();
    const currentMinutes = currentTime.getMinutes();

    const fromTimeParts = fromTime && fromTime.split(":");
    const toTimeParts = toTime && toTime.split(":");

    const fromHours = fromTimeParts && parseInt(fromTimeParts[0], 10);
    const fromMinutes = fromTimeParts && parseInt(fromTimeParts[1], 10);

    const toHours = toTimeParts && parseInt(toTimeParts[0], 10);
    const toMinutes = toTimeParts && parseInt(toTimeParts[1], 10);

    if (
      toHours > fromHours ||
      (toHours === fromHours && toMinutes >= fromMinutes)
    ) {
      return (
        (currentHours > fromHours ||
          (currentHours === fromHours && currentMinutes >= fromMinutes)) &&
        (currentHours < toHours ||
          (currentHours === toHours && currentMinutes <= toMinutes))
      );
    } else {
      return (
        currentHours > fromHours ||
        (currentHours === fromHours && currentMinutes >= fromMinutes) ||
        currentHours < toHours ||
        (currentHours === toHours && currentMinutes <= toMinutes)
      );
    }
  };

  const addToCart = (product) => {
    const data = {
      Product_Id: product._id,
      FKProdID: product.PKID,
      // FKLotID: 10000001,
      Product_name: product.NameToDisplay,
      Product_Quantity: 1,
      Product_detail: product.Description,
      Product_Price: product.SaleRate,

      User_Numberid: Profile?.MobileNumber,
    };

    if (authToken) {
      dispatch(
        AddCartHandler(verfiyToken, authToken, data, data.User_Numberid)
      ).then((result) => {
        if (result.status === "success") {
          dispatch(getCartHandler(verfiyToken, authToken, data.User_Numberid));
        }
      });
    } else {
      dispatch(
        cartActions.pushProToCartListHandler({
          Product_Id: product._id,
          FKProdID: product.PKID,
          // FKLotID: 10000001,
          Product_name: product.NameToDisplay,
          Product_Quantity: 1,
          Product_detail: product.Description,
          Product_Price: product.SaleRate,

          User_Numberid: Profile?.MobileNumber,
        })
      );
      dispatch(cartActions.calculateTotalLocalPriceHandler(product));
    }
  };

  useEffect(() => {
    dispatch(getCategoryHandler(storeById?.StoreId));
  }, [dispatch, storeById]);

  useEffect(() => {
    if (categoryList?.length > 0) {
      const mainCategory = [];

      categoryList.forEach((category) => {
        if (category.parent === null) {
          mainCategory.push(category);
        }
      });
      dispatch(categoryActions.setMainCategory(mainCategory));
    }
  }, [dispatch, categoryList]);

  const ProductDataHandler = (product) => {
    const modifiedName = product.NameToDisplay.replace(/ /g, "-")
      .replace(/'/g, "")
      .replace(/:/g, "-")
      .replace(/\//g, "-");
    const encodedName = encodeURIComponent(modifiedName);
    dispatch(getProductByIdHandler(storeById?.StoreId, product._id));
    navigate(`/${id}/productdetail/${encodedName}/${product._id}`);
  };

  const addToWishlist = (WishlistProductId) => {
    const data = {
      WishlistProductId: WishlistProductId,
      User_Number: Profile?.MobileNumber,
      appKeyToken: verfiyToken,
    };
    if (authToken) {
      dispatch(LoggedInHandler(authToken)).then((result) => {
        if (result.status === "success") {
          dispatch(PostWishlistHandler(authToken, data)).then((result) => {
            if (result.status === "success") {
              dispatch(
                getWishlistHandler(
                  authToken,
                  { appKeyToken: verfiyToken },
                  Profile?.MobileNumber
                )
              );
            } else {
              navigate("/verification");
              dispatch(authActions.setFromPage("cart"));
            }
          });
        }
      });
    } else {
      navigate("/verification");
      dispatch(authActions.setFromPage("cart"));
    }
  };

  const removeWishlist = (id) => {
    dispatch(
      RemoveWishlistHandler(authToken, Profile?.MobileNumber, id, {
        appKeyToken: verfiyToken,
      })
    ).then((result) => {
      if (result.status === "success") {
        dispatch(
          getWishlistHandler(
            authToken,
            { appKeyToken: verfiyToken },
            Profile?.MobileNumber
          )
        );
      } else {
        navigate("/verification");
        dispatch(authActions.setFromPage("cart"));
      }
    });
  };
  const searchByName = (e) => {
    let tempQuery = structuredClone(query);
    tempQuery.search = e.target.value;
    dispatch(productActions.setQueryHandler(tempQuery));
  };

  useEffect(() => {
    if (authToken) {
      dispatch(LoggedInHandler(authToken)).then((result) => {
        if (result.status === "success") {
          const data = {
            User_Numberid: result.data.mobile,
          };
          dispatch(getCartHandler(verfiyToken, authToken, data.User_Numberid));
          dispatch(
            getWishlistHandler(
              authToken,
              { appKeyToken: verfiyToken },
              result.data.mobile
            )
          );
        }
      });
    }
  }, [dispatch, verfiyToken, authToken]);

  const sortedProductList = [...productList].sort((a, b) => {
    const aHasPromotion =
      a.appliedPromotions?.length > 0 ||
      a.appliedPromotionOnProductCategoryVise?.length > 0 ||
      a.appliedPromotionOnProductBrandVise?.length > 0;

    const bHasPromotion =
      b.appliedPromotions?.length > 0 ||
      b.appliedPromotionOnProductCategoryVise?.length > 0 ||
      b.appliedPromotionOnProductBrandVise?.length > 0;

    if (aHasPromotion && bHasPromotion) {
      const aPromotionRate =
        a.appliedPromotions[a?.appliedPromotions?.length - 1]
          ?.ProductPromotionPercentageRate ||
        a.appliedPromotionOnProductCategoryVise[
          a?.appliedPromotionOnProductCategoryVise?.length - 1
        ]?.ProductPromotionCategoryPercentageRate ||
        a.appliedPromotionOnProductBrandVise[
          a?.appliedPromotionOnProductBrandVise?.length - 1
        ]?.ProductPromotionBrandPercentageRate ||
        0;

      const bPromotionRate =
        b.appliedPromotions[b?.appliedPromotions?.length - 1]
          ?.ProductPromotionPercentageRate ||
        b.appliedPromotionOnProductCategoryVise[
          b?.appliedPromotionOnProductCategoryVise?.length - 1
        ]?.ProductPromotionCategoryPercentageRate ||
        b.appliedPromotionOnProductBrandVise[
          b?.appliedPromotionOnProductBrandVise?.length - 1
        ]?.ProductPromotionBrandPercentageRate ||
        0;

      return bPromotionRate - aPromotionRate;
    }

    return bHasPromotion - aHasPromotion;
  });

  const productsWithPromotion = sortedProductList.filter(
    (product) =>
      (product.appliedPromotions?.length > 0 &&
        withinPromotionTime(
          product?.appliedPromotions[product?.appliedPromotions.length - 1]
            ?.ProductPromotionFromTime,
          product?.appliedPromotions[product?.appliedPromotions.length - 1]
            ?.ProductPromotionToTime
        ) &&
        (!product?.appliedPromotions[product?.appliedPromotions.length - 1]
          ?.FKLocationID ||
          product?.appliedPromotions[product?.appliedPromotions.length - 1]
            ?.FKLocationID === storeById?.LocationId)) ||
      (product.appliedPromotionOnProductCategoryVise?.length > 0 &&
        withinPromotionTime(
          product?.appliedPromotionOnProductCategoryVise[
            product?.appliedPromotionOnProductCategoryVise.length - 1
          ]?.ProductCategoryPromotionFromTime,
          product?.appliedPromotionOnProductCategoryVise[
            product?.appliedPromotionOnProductCategoryVise.length - 1
          ]?.ProductCategoryPromotionToTime
        ) &&
        (!product?.appliedPromotionOnProductCategoryVise[
          product?.appliedPromotionOnProductCategoryVise.length - 1
        ]?.FKLocationID ||
          product?.appliedPromotionOnProductCategoryVise[
            product?.appliedPromotionOnProductCategoryVise.length - 1
          ]?.FKLocationID === storeById?.LocationId)) ||
      (product.appliedPromotionOnProductBrandVise?.length > 0 &&
        withinPromotionTime(
          product?.appliedPromotionOnProductBrandVise[
            product?.appliedPromotionOnProductBrandVise.length - 1
          ]?.ProductBrandPromotionFromTime,
          product?.appliedPromotionOnProductBrandVise[
            product?.appliedPromotionOnProductBrandVise.length - 1
          ]?.ProductBrandPromotionToTime
        ) &&
        (!product?.appliedPromotionOnProductBrandVise[
          product?.appliedPromotionOnProductBrandVise.length - 1
        ]?.FKLocationID ||
          product?.appliedPromotionOnProductBrandVise[
            product?.appliedPromotionOnProductBrandVise.length - 1
          ]?.FKLocationID === storeById?.LocationId))
  );

  const getProductMaxPromotion = (products) => {
    if (products?.length === 0) {
      return 0;
    }

    return products.reduce((maxPromotion, product) => {
      const appliedPromotion =
        product.appliedPromotions?.length > 0
          ? product.appliedPromotions[product.appliedPromotions?.length - 1]
              ?.ProductPromotionPercentageRate || 0
          : 0;

      const categoryPromotion =
        product.appliedPromotionOnProductCategoryVise?.length > 0
          ? product.appliedPromotionOnProductCategoryVise[
              product.appliedPromotionOnProductCategoryVise?.length - 1
            ]?.ProductPromotionCategoryPercentageRate || 0
          : 0;

      const brandPromotion =
        product.appliedPromotionOnProductBrandVise?.length > 0
          ? product.appliedPromotionOnProductBrandVise[
              product.appliedPromotionOnProductBrandVise?.length - 1
            ]?.ProductPromotionBrandPercentageRate || 0
          : 0;

      const productPromotion =
        appliedPromotion > 0 && categoryPromotion > 0
          ? appliedPromotion
          : Math.max(appliedPromotion, categoryPromotion, brandPromotion);

      return Math.max(maxPromotion, productPromotion);
    }, 0);
  };

  useEffect(() => {
    const handleBackNavigation = () => {
      const tempQuery = structuredClone(query);
      tempQuery.categories = "";
      tempQuery.Brand = "";
      dispatch(productActions.setQueryHandler(tempQuery));

      navigate(`/${id}/home`);
    };

    window.history.pushState({}, "");
    window.onpopstate = handleBackNavigation;

    return () => {
      window.onpopstate = null;
    };
  }, [dispatch, query, navigate]);

  useEffect(() => {
    const matchedCategory = categoryList.find(
      (item) => item._id === categoryId
    );
    const matchedCategoryName = matchedCategory ? matchedCategory.name : null;
    dispatch(categoryActions.setCategoryName(matchedCategoryName));
  }, []);

  return (
    <>
      <div className={`${classes.CategoryPage} container-fluid `}>
        <div className={`${classes.CategoryPageHeader}`}>
          <MainHeader />
        </div>

        <div className={`${classes.CategoryWrapper}`}>
          <Header
            onClick={BackToHome}
            heading={`${mainCategoryName}`}
            jsxheading={
              showInput ? (
                <input
                  type="search"
                  className={`${classes.inputSearch} form-control `}
                  id="exampleInputEmail1"
                  aria-label="Search"
                  placeholder="Search"
                  onChange={searchByName}
                  value={query.search}
                />
              ) : (
                <div className="mb-2" onClick={() => setShowInput(true)}>
                  {/* <SearchSvg /> */}
                  <i class="bi bi-search"></i>
                </div>
              )
            }
          />

          <div className={`${classes.ContentMain} row `}>
            <div className={`col-lg-2 col-md-2 col-sm-2 col-xs-3 col-3 p-0`}>
              <div className={`${classes.leftBox}`}>
                {subCategory?.length > 0
                  ? subCategory?.map((subCategory, index) => {
                      const categoryProducts = productsWithPromotion?.filter(
                        (product) => product.Category?.includes(subCategory._id)
                      );
                      const maxPromotion =
                        getProductMaxPromotion(categoryProducts);
                      return (
                        <div
                          className="col-12 "
                          key={index}
                          onClick={() => findProductByCategory(subCategory)}
                        >
                          <div className={`${classes.subBox}`}>
                            <div
                              className={`${classes.categoryOfferBoxImage}  position-relative`}
                            >
                              <img
                                src={
                                  subCategory?.image
                                    ? subCategory?.image
                                    : offerImg
                                }
                                alt="product"
                                className="img-fluid"
                              />
                              {maxPromotion > 0 && (
                                <div className={classes.slantBanner}>
                                  <span className={classes.UptoText}>UPTO</span>

                                  <span className={classes.discounText}>
                                    {maxPromotion}% OFF
                                  </span>
                                </div>
                              )}
                            </div>
                            <p className="mb-0">{subCategory.name}</p>
                          </div>
                        </div>
                      );
                    })
                  : mainCategory?.map((category, index) => {
                      const categoryProducts = productsWithPromotion?.filter(
                        (product) => product.Category?.includes(category._id)
                      );

                      const maxPromotion =
                        getProductMaxPromotion(categoryProducts);
                      return (
                        <div
                          className="col-12 "
                          key={index}
                          onClick={() => findProductByMainCategory(category)}
                        >
                          <div className={`${classes.subBox}`}>
                            <div
                              className={`${classes.categoryOfferBoxImage}  position-relative`}
                            >
                              <img
                                src={
                                  category?.image ? category?.image : offerImg
                                }
                                alt="product"
                                className="img-fluid"
                              />
                            </div>
                            <p className="mb-0">{category.name}</p>
                            {maxPromotion > 0 && (
                              <div className={classes.slantBanner}>
                                <span className={classes.UptoText}>UPTO</span>

                                <span className={classes.discounText}>
                                  {maxPromotion}% OFF
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    })}
              </div>
            </div>
            {/* <div
              // className={`${classes.sideContentCategory} col-lg-1 col-md-2 col-sm-2 col-xs-3 col-3`}
              className={
                toggleState === true
                  ? `${classes.mainCategoryContent} col-lg-1 col-md-2 col-sm-2 col-xs-3 col-3 `
                  : `${classes.mainCategoryContent}  ${classes.ContentSide}`
              }
            >
              <div
                className={`${classes.sideContent}   collapse-horizontal `}
                id="collapseWidthExample"
              >
                <div className="d-flex w-100 justify-content-end ">
                  <i
                    className="bi bi-x-circle me-1"
                    onClick={() => setToggleState(!toggleState)}
                    data-bs-toggle="collapse-horizontal"
                    data-bs-target="#collapseWidthExample"
                    aria-expanded="true"
                    aria-controls="collapseWidthExample"
                  ></i>
                </div>
                <div className={showItem ? "row mb-5" : "row"}>
                  {subCategory?.length > 0
                    ? subCategory?.map((subCategory, index) => {
                        const categoryProducts = productsWithPromotion?.filter(
                          (product) =>
                            product.Category?.includes(subCategory._id)
                        );
                        const maxPromotion =
                          getProductMaxPromotion(categoryProducts);
                        return (
                          <div
                            className="col-12 "
                            key={index}
                            onClick={() => findProductByCategory(subCategory)}
                          >
                            <div className="d-block text-center  ">
                              <div className={`${classes.categoryList} `}>
                                <div
                                  className={`${classes.categoryOfferImage}  position-relative`}
                                >
                                  <img
                                    src={
                                      subCategory?.image
                                        ? subCategory?.image
                                        : offerImg
                                    }
                                    alt="product"
                                    className="img-fluid"
                                  />
                                  {maxPromotion > 0 && (
                                    <div className={classes.slantBanner}>
                                      <span className={classes.UptoText}>
                                        UPTO
                                      </span>

                                      <span className={classes.discounText}>
                                        {maxPromotion}% OFF
                                      </span>
                                    </div>
                                  )}
                                </div>
                                <p>{subCategory.name}</p>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    : mainCategory?.map((category, index) => {
                        const categoryProducts = productsWithPromotion?.filter(
                          (product) => product.Category?.includes(category._id)
                        );

                        const maxPromotion =
                          getProductMaxPromotion(categoryProducts);
                        return (
                          <div
                            className="col-12 "
                            key={index}
                            onClick={() => findProductByCategory(category)}
                          >
                            <div className="d-block text-center  ">
                              <div className={`${classes.categoryList} `}>
                                <div
                                  className={`${classes.categoryOfferImage}  position-relative`}
                                >
                                  <img
                                    src={
                                      category?.image
                                        ? category?.image
                                        : offerImg
                                    }
                                    alt="product"
                                    className="img-fluid"
                                  />
                                </div>
                                <p>{category.name}</p>
                                {maxPromotion > 0 && (
                                  <div className={classes.slantBanner}>
                                    <span className={classes.UptoText}>
                                      UPTO
                                    </span>

                                    <span className={classes.discounText}>
                                      {maxPromotion}% OFF
                                    </span>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                </div>
              </div>
              <p>
                <button
                  className={
                    toggleState === false
                      ? ` ${classes.toggleBtn}`
                      : `${classes.hideBtn}`
                  }
                  type="button"
                  data-bs-toggle="collapse-horizontal"
                  data-bs-target="#collapseWidthExample"
                  aria-expanded="true"
                  aria-controls="collapseWidthExample"
                  onClick={() => setToggleState(!toggleState)}
                >
                  {" "}
                  S <br /> U <br /> B<br />
                  C <br />
                  A <br />T<br />E<br />G<br />O<br />R<br />Y
                </button>
              </p>
            </div> */}
            <div
              className={
                toggleState === true
                  ? `${classes.mainCategoryContent} col-lg-10 col-md-10 col-sm-10 col-xs-9 col-9 p-0`
                  : `${classes.mainCategoryContent} col-lg-11 col-md-11 col-sm-11 col-xs-11 col-11 p-0`
              }
            >
              <div className={`${classes.categoryNameTExt}`}>
                <h6>{categoryName}</h6>
                {/* <Filter
                  content={
                    <div
                      className={`${classes.filterBtn} `}
                      data-bs-toggle="modal"
                      data-bs-target="#staticBackdrop"
                    >
                      {" "}
                      <FilterSvg /> Filter
                    </div>
                  }
                /> */}
              </div>
              {sortedProductList.length > 0 ? (
                <div
                  className={
                    showItem
                      ? "row mt-1 mb-4 ms-1"
                      : ` row  ${classes.catagoryProductMain}  `
                  }
                >
                  {loading ? (
                    <div className={`${classes.setLoader}`}>
                      {" "}
                      {/* <Loader />{" "} */}
                      <PageLoader />{" "}
                    </div>
                  ) : (
                    sortedProductList?.map((product, index) => {
                      const findProd = authToken
                        ? cartList?.find(
                            (cartprod) => cartprod.Product_Id === product._id
                          )
                        : cartData?.find(
                            (cartprod) => cartprod.Product_Id === product._id
                          );
                      const findFav = wishlist?.WishlistProductId?.find(
                        (wish) => wish === product.PKID
                      );
                      return (
                        <div
                          className={`  ${classes.productAlign} col-6 col-lg-3 col-md-3`}
                          key={index}
                        >
                          <div className="d-block text-center  ">
                            <div className={`${classes.productList} `}>
                              {/* <div className={`${classes.favourite} `}>
                                {findFav ? (
                                  <div
                                    onClick={() => removeWishlist(product.PKID)}
                                  >
                                    <FavouriteSvg />
                                  </div>
                                ) : (
                                  <div
                                    onClick={() => addToWishlist(product.PKID)}
                                  >
                                    <NotFavouriteSvg />
                                  </div>
                                )}
                              </div> */}
                              {product?.appliedPromotions?.length > 0 &&
                                product?.appliedPromotionOnProductCategoryVise
                                  ?.length === 0 &&
                                product?.appliedPromotionOnProductBrandVise
                                  ?.length === 0 &&
                                withinPromotionTime(
                                  product?.appliedPromotions?.[
                                    product?.appliedPromotions.length - 1
                                  ]?.ProductPromotionFromTime,
                                  product?.appliedPromotions?.[
                                    product?.appliedPromotions.length - 1
                                  ]?.ProductPromotionToTime
                                ) &&
                                (!product?.appliedPromotions[
                                  product?.appliedPromotions.length - 1
                                ]?.FKLocationID ||
                                  product?.appliedPromotions[
                                    product?.appliedPromotions.length - 1
                                  ]?.FKLocationID ===
                                    storeById?.LocationId) && (
                                  <div className={`${classes.promotion}`}>
                                    <div
                                      className={`${classes.promotionPrice}`}
                                    >
                                      {
                                        product?.appliedPromotions[
                                          product?.appliedPromotions.length - 1
                                        ]?.ProductPromotionPercentageRate
                                      }
                                      % off
                                    </div>
                                  </div>
                                )}

                              {product?.appliedPromotions?.length > 0 &&
                                (product?.appliedPromotionOnProductCategoryVise
                                  ?.length > 0 ||
                                  product?.appliedPromotionOnProductBrandVise
                                    ?.length > 0) &&
                                withinPromotionTime(
                                  product?.appliedPromotions?.[
                                    product?.appliedPromotions.length - 1
                                  ]?.ProductPromotionFromTime,
                                  product?.appliedPromotions?.[
                                    product?.appliedPromotions.length - 1
                                  ]?.ProductPromotionToTime
                                ) &&
                                (!product?.appliedPromotions[
                                  product?.appliedPromotions.length - 1
                                ]?.FKLocationID ||
                                  product?.appliedPromotions[
                                    product?.appliedPromotions.length - 1
                                  ]?.FKLocationID ===
                                    storeById?.LocationId) && (
                                  <div className={`${classes.promotion}`}>
                                    <div
                                      className={`${classes.promotionPrice}`}
                                    >
                                      {
                                        product?.appliedPromotions[
                                          product?.appliedPromotions.length - 1
                                        ]?.ProductPromotionPercentageRate
                                      }
                                      % off
                                    </div>
                                  </div>
                                )}

                              {product?.appliedPromotions?.length === 0 &&
                                product?.appliedPromotionOnProductCategoryVise
                                  ?.length > 0 &&
                                product?.appliedPromotionOnProductBrandVise
                                  ?.length > 0 &&
                                withinPromotionTime(
                                  product
                                    ?.appliedPromotionOnProductCategoryVise[
                                    product
                                      ?.appliedPromotionOnProductCategoryVise
                                      .length - 1
                                  ]?.ProductCategoryPromotionFromTime,
                                  product
                                    ?.appliedPromotionOnProductCategoryVise[
                                    product
                                      ?.appliedPromotionOnProductCategoryVise
                                      .length - 1
                                  ]?.ProductCategoryPromotionToTime
                                ) &&
                                (!product
                                  ?.appliedPromotionOnProductCategoryVise[
                                  product?.appliedPromotionOnProductCategoryVise
                                    .length - 1
                                ]?.FKLocationID ||
                                  product
                                    ?.appliedPromotionOnProductCategoryVise[
                                    product
                                      ?.appliedPromotionOnProductCategoryVise
                                      .length - 1
                                  ]?.FKLocationID ===
                                    storeById?.LocationId) && (
                                  <div className={`${classes.promotion}`}>
                                    <div
                                      className={`${classes.promotionPrice}`}
                                    >
                                      {
                                        product
                                          ?.appliedPromotionOnProductCategoryVise[
                                          product
                                            ?.appliedPromotionOnProductCategoryVise
                                            .length - 1
                                        ]
                                          ?.ProductPromotionCategoryPercentageRate
                                      }
                                      % off
                                    </div>
                                  </div>
                                )}

                              {product?.appliedPromotionOnProductCategoryVise
                                ?.length > 0 &&
                                product?.appliedPromotions?.length === 0 &&
                                product?.appliedPromotionOnProductBrandVise
                                  ?.length === 0 &&
                                withinPromotionTime(
                                  product
                                    ?.appliedPromotionOnProductCategoryVise[
                                    product
                                      ?.appliedPromotionOnProductCategoryVise
                                      .length - 1
                                  ]?.ProductCategoryPromotionFromTime,
                                  product
                                    ?.appliedPromotionOnProductCategoryVise[
                                    product
                                      ?.appliedPromotionOnProductCategoryVise
                                      .length - 1
                                  ]?.ProductCategoryPromotionToTime
                                ) &&
                                (!product
                                  ?.appliedPromotionOnProductCategoryVise[
                                  product?.appliedPromotionOnProductCategoryVise
                                    .length - 1
                                ]?.FKLocationID ||
                                  product
                                    ?.appliedPromotionOnProductCategoryVise[
                                    product
                                      ?.appliedPromotionOnProductCategoryVise
                                      .length - 1
                                  ]?.FKLocationID ===
                                    storeById?.LocationId) && (
                                  <div className={`${classes.promotion}`}>
                                    <div
                                      className={`${classes.promotionPrice}`}
                                    >
                                      {
                                        product
                                          ?.appliedPromotionOnProductCategoryVise[
                                          product
                                            ?.appliedPromotionOnProductCategoryVise
                                            .length - 1
                                        ]
                                          ?.ProductPromotionCategoryPercentageRate
                                      }
                                      % off
                                    </div>
                                  </div>
                                )}

                              {product?.appliedPromotionOnProductBrandVise
                                ?.length > 0 &&
                                product?.appliedPromotionOnProductCategoryVise
                                  ?.length === 0 &&
                                product?.appliedPromotions?.length === 0 &&
                                withinPromotionTime(
                                  product?.appliedPromotionOnProductBrandVise[
                                    product?.appliedPromotionOnProductBrandVise
                                      .length - 1
                                  ]?.ProductBrandPromotionFromTime,
                                  product?.appliedPromotionOnProductBrandVise[
                                    product?.appliedPromotionOnProductBrandVise
                                      .length - 1
                                  ]?.ProductBrandPromotionToTime
                                ) &&
                                (!product?.appliedPromotionOnProductBrandVise[
                                  product?.appliedPromotionOnProductBrandVise
                                    .length - 1
                                ]?.FKLocationID ||
                                  product?.appliedPromotionOnProductBrandVise[
                                    product?.appliedPromotionOnProductBrandVise
                                      .length - 1
                                  ]?.FKLocationID ===
                                    storeById?.LocationId) && (
                                  <div className={`${classes.promotion}`}>
                                    <div
                                      className={`${classes.promotionPrice}`}
                                    >
                                      {
                                        product
                                          ?.appliedPromotionOnProductBrandVise[
                                          product
                                            ?.appliedPromotionOnProductBrandVise
                                            .length - 1
                                        ]?.ProductPromotionBrandPercentageRate
                                      }
                                      % off
                                    </div>
                                  </div>
                                )}
                              <div
                                className={`${classes.categoryImage}  position-relative`}
                                onClick={() => ProductDataHandler(product)}
                              >
                                {product?.Images?.length > 0 &&
                                product?.Images ? (
                                  <img
                                    variant="top"
                                    src={product?.Images[0]}
                                    alt="imageNotAvailable"
                                    className="img-fluid"
                                  />
                                ) : (
                                  <img
                                    variant="top"
                                    src={noImg}
                                    alt="imageNotAvailable"
                                    className="img-fluid"
                                  />
                                )}
                              </div>
                              <div className={`${classes.productName}  pt-2`}>
                                <h6
                                  className={`${classes.categoryBrand} text-capitalize mb-0`}
                                  onClick={() => ProductDataHandler(product)}
                                >
                                  {product?.ERPBrand}
                                </h6>
                                <h6
                                  className={`${classes.categoryProductName} text-capitalize mb-0`}
                                  onClick={() => ProductDataHandler(product)}
                                >
                                  {product?.Product}
                                </h6>
                                <div className={`${classes.rating} pb-1  `}>
                                  {Array.from({ length: 5 }, (_, index) => {
                                    return index <
                                      Math.round(
                                        product?.AverageProductRating
                                      ) ? (
                                      <i
                                        key={index}
                                        className="bi bi-star-fill pe-1"
                                      ></i>
                                    ) : (
                                      <i
                                        key={index}
                                        className="bi bi-star pe-1"
                                      ></i>
                                    );
                                  })}
                                </div>
                                <p className={`${classes.amount} mb-0`}>
                                  ₹{product.SaleRate}{" "}
                                  {/* <span className={`${classes.previousAmount} `}>
                              <del>₹{product.MRP * 2} </del>
                            </span>{" "} */}
                                </p>

                                {/* <div className={`${classes.rating} py-1 `}>
                                                <i className="bi bi-star-fill"></i>
                                                <i className="bi bi-star-fill px-1"></i>
                                                <i className="bi bi-star-fill"></i>
                                                <i className="bi bi-star-half px-1"></i>
                                                <i className="bi bi-star"></i>
                                            </div> */}

                                <div className={`${classes.CartButton}`}>
                                  {findProd ? (
                                    <button className={`${classes.item}`}>
                                      <AddToCart product={findProd} />
                                    </button>
                                  ) : (
                                    <button
                                      className={`${classes.cartBtn}`}
                                      onClick={() => addToCart(product)}
                                    >
                                      <CartSvg /> ADD
                                    </button>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  )}
                </div>
              ) : (
                <div className={`${classes.NoProductsImageFound} `}>
                  <img src={NoProductsImage} alt="no products" />
                </div>
              )}
            </div>
            {msg && <h4 className="text-danger">{msg}</h4>}
          </div>

          {authToken ? (
            <>
              {cartList?.length > 0 ? (
                <Footer onClick={() => navigate(`/${id}/cart`)} />
              ) : (
                ""
              )}
            </>
          ) : (
            <>
              {cartData?.length > 0 ? (
                <Footer onClick={() => navigate(`/${id}/cart`)} />
              ) : (
                ""
              )}
            </>
          )}
        </div>
      </div>
      <MainFooter />
    </>
  );
};

export default AllCategory;
