import React, { useState } from "react";
import Header from "../../UI/Header/Header";
import { useLocation, useNavigate,useParams} from "react-router-dom";
import classes from "./Order.module.css";
import { ReactComponent as VisaSvg } from "../../Assets/SvgComponents/VisaSvg.svg";
import AxisBank from "../../Assets/Images/AxisBank.png";

import {
  OrderCancelHandle,
  OrderPromoHandle,
  getOrderByIdHandler,
  trackOrderStatus,
} from "../../Store/Order/OrderActions";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import noImg from "../../Assets/Images/noImage.png";
import { orderActions } from "../../Store/Order/OrderSlice";
import { getStoreApprovalHandler } from "../../Store/Order/OrderActions";
import MainHeader from "../Home/MainHeader";
import MainFooter from "../../UI/Footer/MainFooter";
import Loader from "../../UI/Loader/Loader";

const Order = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const orderById = useSelector((state) => state.order.orderById);
  const verfiyToken = useSelector((state) => state.auth.verfiyToken);
  const productList = useSelector((state) => state.product.productList);
  const orderTrackStatus = useSelector((state) => state.order.orderTrackStatus);
  const Customer = useSelector((state) => state.auth.Customer);
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState("");
  const storeById = useSelector((state) => state.store.storeById);
  console.log("storeById-----", storeById)




  const orderTrackingList = useSelector(
    (state) => state.order.orderTrackingList
  );
  const authToken = localStorage.getItem("authToken");

  const dispatch = useDispatch();
  const { id } = useParams();
  const { orderId } = useParams();

  const CancelOrder = () => {
    setLoading(true);
    const data = {
      CustomerID: Customer?._id,
      OrderID: orderById?._id,
      appKeyToken: verfiyToken,
    };
    dispatch(OrderCancelHandle(authToken, data)).then((result) => {
      if (result.status === "success") {
        navigate(`/CancelOrder/${orderId}`);
        setLoading(false);
      } else {
        setMsg("Your order cannot be cancelled now");
        setLoading(false);
      }
    });
  };

  const handleHeaderClick = () => {
    if (location.key === 'default') {
      navigate(`/${id}/home`);
    } else {
      navigate(-1);
    }
  };

  useEffect(() => {
    if (orderId) {
      dispatch(getOrderByIdHandler(verfiyToken, orderId));
      dispatch(
        trackOrderStatus(authToken, { appKeyToken: verfiyToken }, orderId)
      ).then((result) => {
        if (result.status === "success") {
          dispatch(getOrderByIdHandler(verfiyToken, orderId)).then((result) => {
            if (result.status === "success") {
              console.log(result.data)
              dispatch(
                orderActions.setStatusHandler(
                  // result.data.OrderFulfillment.CurrentProcessingStatus
                  result.data.CustomerDetail[0].OrderFulfillment.CurrentProcessingStatus
                )
              );
            } else {
              dispatch(orderActions.setStatusHandler());
            }
          });
        } else {
          dispatch(orderActions.setStatusHandler());
        }
      });
    }
  }, []);

  useEffect(() => {
    const data = {
      CustomerID: Customer?._id,
      OrderID: orderById?._id,
      StoreId:storeById?.StoreId,
      LocationId:10000001
    };

    
    dispatch(OrderPromoHandle(verfiyToken, data));
    dispatch(getStoreApprovalHandler(verfiyToken,storeById?.StoreId)).then((result) => {
      if (result.data.CustomerApproval === true) {
        dispatch(orderActions.setOrderStatusListHandler(result.data.OrderSt));
      } else {
        dispatch(orderActions.setOrderStatusListHandler());
      }
    });
  }, []);

  

  return (
    <>
      <div className={`container-fluid ${classes.OrderPage}`}>
        <div className={`${classes.OrderNavigation}`}>
          <MainHeader />
        </div>
        <div className={`${classes.OrderWrapper}`}>
          <Header heading={"Order"} onClick= {handleHeaderClick}/>

          <div className={`${classes.OrderSummaryRow} `}>
            {orderById?.CustomerDetail &&
              orderById?.CustomerDetail.length > 0 &&
              orderById?.CustomerDetail[0]?.ProdDetail?.map(
                (product, index) => {
                  const correspondingProduct = productList.find(
                    (item) => item.PKID === product?.FKProdID
                  );

                  return (
                    <div className={`${classes.OrderSummaryBox} `} key={index}>
                      <div className="d-flex align-items-center ">
                        {correspondingProduct?.Images?.length > 0 &&
                        correspondingProduct?.Images ? (
                          <img
                            variant="top"
                            src={correspondingProduct?.Images[0]}
                            className={`${classes.OrderImage}`}
                            alt="img"
                          />
                        ) : (
                          <img
                            src={noImg}
                            className={`${classes.OrderImage}`}
                            alt="img"
                          />
                        )}
                      </div>
                      <div
                        className={`${classes.Product} ${classes.productCont} mt-2 `}
                      >
                        <h6>{product?.Product_name}</h6>
                        <p>
                          Qty : <span className="fw-bold">{product?.Qty}</span>{" "}
                        </p>
                        <div>
                          <span className={`${classes.Amount}`}>
                            {product?.Rate}
                          </span>
                          <span className={`${classes.Rupee} ms-1`}>₹</span>
                          {/* <del className={`${classes.PreviousAmount} ms-2`}>
                    {product?.MRP * 2}
                  </del> */}
                        </div>
                      </div>
                    </div>
                  );
                }
              )}
          </div>
          <div className="">
            <div className={`${classes.orderShipping} col-12`}>
              <h6>ORDER ID - {orderById?._id}</h6>
              <i className="bi bi-share"></i>
            </div>
            <div className={`${classes.orderSteps}`}>
              <ul className={`${classes.events}`}>
                {orderTrackingList?.length > 0 &&
                  orderTrackingList?.map((set, index) => (
                    <li
                      key={index}
                      className={
                        orderTrackStatus === set.OrderStatusname
                          ? `${classes.newStatus}`
                          : index <=
                            orderTrackingList.findIndex(
                              (item) =>
                                item.OrderStatusname === orderTrackStatus
                            )
                          ? `${classes.newStatus}`
                          : ""
                      }
                    >
                      {/* <div className={`${classes.set}`}></div> */}
                      <span>
                        <strong>{set.OrderStatusname}</strong>{" "}
                      </span>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
          <div className={`${classes.footer} `}>
            {orderById?.CustomerDetail[0]?.OrderFulfillment
              ?.CurrentProcessingStatus === "Cancelled" ? (
              <p className="text-danger">
                Your order has been cancelled as per your request.
              </p>
            ) : (
              <>
                {msg && <p className="text-danger">{msg}</p>}

                <div
                  className={`${classes.cancelText}`}
                  data-bs-toggle="modal"
                  data-bs-target="#staticBackdrop"
                >
                  {loading === false ? (
                    "Cancel"
                  ) : (
                    <div className={`${classes.Load}`}>
                      {" "}
                      <Loader />{" "}
                    </div>
                  )}
                </div>
                <div className={`${classes.horizontalBorder}`}></div>
                <div className={`${classes.supportText}`}>
                  <i className="bi bi-telephone me-1"></i>
                  Help
                </div>
              </>
            )}
          </div>

          <div className={`${classes.addressAndPrice} `}>
            <div className={`${classes.addressBox} `}>
              <div className={`${classes.orderShipping}  col-12`}>
                <h6>SHIPPING DETAILS</h6>
              </div>
              <div className={`${classes.AddressText} border-bottom-0 `}>
                {orderById?.CustomerDetail[0]?.ShippingAddress?.Street1} <br />
                {orderById?.CustomerDetail[0]?.ShippingAddress?.Street2} <br />
                {orderById?.CustomerDetail[0]?.ShippingAddress?.City} -{" "}
                {orderById?.CustomerDetail[0]?.ShippingAddress?.Pincode} <br /> Mobile : +91{" "}
                {orderById?.CustomerDetail[0]?.ShippingAddress?.Mobile}
              </div>
            </div>
            <div className={`${classes.priceBox} `}>
              <div className={`${classes.orderShipping}  col-12`}>
                <h6>PAYMENT DETAILS</h6>
                <a href={orderById?.InvoiceDetails} target="blank">
                  {" "}
                  <p>View Invoice</p>
                </a>
              </div>

              <div className={`${classes.CreditState} gap-2`}>
                <img src={AxisBank} alt="axis" className="img-fluid" />

                <div className={`${classes.AddMain} d-flex flex-column ms-2`}>
                  <p>Axis Bank</p>
                  <div className="d-flex align-items-center gap-2">
                    <p>XXXX 4002</p>
                    <VisaSvg />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-5">
            <div className="col-12 ">
              <div className={`${classes.PaymentPricing}`}>
                <p>Price</p>
                <p>₹{orderById?.CustomerDetail?.[0]?.total_amount}</p>
              </div>
              <div className={`${classes.PaymentPricing}`}>
                <p>Delivery Charges</p>
                <p className={`${classes.freeText}`}>FREE</p>
              </div>
            </div>
            <div className={`${classes.PriceBorder}`}></div>
            <div className="container-fluid ">
              <div className={`${classes.PaymentTotal}`}>
                <h6>Amount Paid</h6>
                <h6>₹{orderById?.CustomerDetail?.[0]?.total_amount}</h6>
              </div>
            </div>
          </div>

          <div
            className={`${classes.paymentModal} modal  fade`}
            id="staticBackdrop"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div
              className={`modal-dialog  modal-dialog-scrollable ${classes.cancelModalContent} `}
            >
              <div className={`modal-content ${classes.paymentModalContent} `}>
                <div className={`modal-header ${classes.paymentModalHeader} `}>
                  <div className={`${classes.FilterHeader} `}>
                    <button
                      type="button"
                      className="btn-close "
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                </div>
                <div className={`modal-body ${classes.paymentModalBody} py-2 `}>
                  <div className="pt-0 px-2 ">
                    <span className={`${classes.sureText}`}>
                      Are you sure you want to cancel ?
                    </span>
                  </div>
                  <div className="d-flex justify-content-around gap-4">
                    <button
                      className={`${classes.dontCancelBtn}`}
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      Don’t cancel
                    </button>
                    <button
                      className={`${classes.cancelBtn}`}
                      onClick={CancelOrder}
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      Cancel order
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <MainFooter />
    </>
  );
};

export default Order;
