import { configureStore, combineReducers } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";

import authSlice from "./Auth/AuthSlice";
import categorySlice from "./Category/CategorySlice";
import productSlice from "./Product/ProductSlice";
import uiSlice from "../Pages/UI/UISlice";
import brandSlice from "./Brand/BrandSlice";
import cartSlice from "./Cart/CartSlice";
import addressSlice from "./Address/AddressSlice";
import storeSlice from "./StoreDetail/StoreSlice";
import orderSlice from "./Order/OrderSlice";

// configuration for persist
const persistConfig = {
  key: "root",
  version: 1,
  storage: storage,
};

const appReducer = combineReducers({
  auth: authSlice.reducer,
  category: categorySlice.reducer,
  product: productSlice.reducer,
  ui: uiSlice.reducer,
  brand: brandSlice.reducer,
  cart: cartSlice.reducer,
  address: addressSlice.reducer,
  store: storeSlice.reducer,
  order: orderSlice.reducer,
});

const rootReducer = (state, action) => {
  if (action.type === "reset") {
    state = undefined;
  }

  return appReducer(state, action);
};

//persisting the reducers
const persistedReducer = persistReducer(persistConfig, rootReducer);

// confiuring the redux store
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  devTools: {
    latency: 50,
  },
});

export default store;
